<template>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center mr-2">
      <feather-icon
        icon="CalendarIcon"
        class="mr-75"
      />
      <span class="font-weight-bold">{{ t('facilities.field.contract.label') }}</span>
    </div>
    <div class="datepicker-container">
      <EditInput
        :model="formattedDate"
        :toggle-only-by-icon="false"
        :readonly="readonly"
        @input="$emit('updateField', { field: 'contract_till', value: $event })"
        @edit="openDatePicker"
      >
        {{ formattedDate || t('facilities.field.contract.placeholder') }}
      </EditInput>
      <BDropdown
        ref="dropdown"
        no-caret
        :right="isMobile"
      >
        <template #button-content />

        <BCalendar
          v-bind="labels"
          hide-header
          :start-weekday="1"
          label-help=""
          @selected="dateSelected"
        />
      </BDropdown>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from '@vue/composition-api';
import {
  BDropdown,
  BCalendar,
} from 'bootstrap-vue';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from "@/hooks/useStore";
import EditInput from '../editInput/EditInput.vue';

export default {
  components: {
    EditInput,
    BDropdown,
    BCalendar,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { item } = toRefs(props);
    const isMobile = ref(false);
    const store = useStore();

    isMobile.value = store.state.app.isMobile;

    const formattedDate = computed(() => (item.value.contract_till ? (new Date(item.value.contract_till)).toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' }) : null));

    const dropdown = ref(null);
    const openDatePicker = () => {
      dropdown.value.show(true);
    };
    const dateSelected = (e) => {
      emit('updateField', { field: 'contract_till', value: e });
      dropdown.value.hide(true);
    };

    const labels = {
      labelPrevDecade: 'Прошлое десятилетие',
      labelPrevYear: 'Прошлый год',
      labelPrevMonth: 'Прошлый месяц',
      labelCurrentMonth: 'Текущий месяц',
      labelNextMonth: 'Следующий месяц',
      labelNextYear: 'Следующий год',
      labelNextDecade: 'Следующее десятилетие',
      labelToday: 'Сегодня',
      labelSelected: 'Выбранная дата',
      labelNoDateSelected: 'Дата не выбрана',
      labelCalendar: 'Календарь',
      labelNav: 'Навигация',
      labelHelp: '',
    };

    return {
      t,
      formattedDate,
      openDatePicker,
      dateSelected,
      dropdown,
      labels,
      isMobile,
    };
  },
};
</script>

<style lang="sass">
.datepicker-container
  position: relative
  & .dropdown
    position: absolute
    top: 100
    & > button
      z-index: -1
      pointer-events: none
      height: 0px
      width: 0px
      min-height: 0px
      min-width: 0px
      display: block
      flex: 0
      padding: 0
      margin: 0
      border: unset
    & > ul
      margin-top: 0
      padding-bottom: 0
      & .b-calendar-grid-help
        display: none
</style>
