<template>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center mr-2">
      <feather-icon
        icon="UserIcon"
        class="mr-75"
      />
      <span class="font-weight-bold">{{ t('facilities.field.manager.label') }}</span>
    </div>
    <EditInput
      :model="selectedManagerName"
      :with-select="true"
      :toggle-only-by-icon="false"
      :hide-edit-icon="true"
      :only-exists="true"
      :options="options"
      :readonly="readonly"
      @input="onInput"
    >
      {{ selectedManagerName || t('facilities.field.manager.placeholder') }}
    </EditInput>
  </div>
</template>

<script>
import { computed, ref, toRefs } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import EditInput from '../editInput/EditInput.vue';

export default {
  components: { EditInput },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isNew: Boolean,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const { item, isNew } = toRefs(props);
    const store = useStore();
    const { t } = useI18n();

    const employees = ref([]);
    (async () => {
      const { result, data } = await store.dispatch('employees/getEmployeesForSelector');
      if (result) {
        employees.value = data.map((i) => ({
          label: `${i.firstname} ${i.surname}`,
          firstname: i.firstname,
          id: i.id,
          patronymic: i.patronymic,
          surname: i.surname,
          value: i.id,
        }));
      }
    })();
    const options = computed(() => employees.value);

    const localValue = ref(null);
    const selectedManagerName = computed(() => {
      if (!isNew.value || item.value.manager_id) {
        return item.value.manager_id ? `${item.value.manager?.firstname} ${item.value.manager?.surname}` : null;
      } return localValue.value;
    });

    const onInput = (value) => {
      const manager = options.value.find((i) => i.value === value);
      localValue.value = manager.label;
      emit('updateField', {field: 'manager', value: manager, update: true});
      emit('updateField', {field: 'manager_id', value: manager.id, update: true});
    };

    return {
      t,
      options,
      selectedManagerName,
      onInput,
    };
  },
};
</script>
