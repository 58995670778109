export default function () {
    const types = {
        art: 'image',
        asf: 'video',
        asx: 'video',
        avi: 'video',
        avx: 'video',
        bmp: 'image',
        dib: 'image',
        dv: 'video',
        gif: 'image',
        ief: 'image',
        jpe: 'image',
        jpeg: 'image',
        jpg: 'image',
        mac: 'image',
        mov: 'video',
        movie: 'video',
        mp4: 'video',
        mpe: 'video',
        mpeg: 'video',
        mpg: 'video',
        mpv2: 'video',
        ogv: 'video',
        axv: 'video',
        pbm: 'image',
        pct: 'image',
        pgm: 'image',
        pic: 'image',
        pict: 'image',
        png: 'image',
        pnm: 'image',
        pnt: 'image',
        ppm: 'image',
        psd: 'image',
        qt: 'video',
        qti: 'image',
        qtif: 'image',
        ras: 'image',
        rgb: 'image',
        svg: 'image',
        svgz: 'image',
        webp: 'image',
        wmv: 'video',
    };

    const getFileType = (ext) => types[ext.toLowerCase()];

    return {
        getFileType,
    };
}
