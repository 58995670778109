import {
  ref, watch, computed, nextTick,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRouter } from '@/hooks/useRouter';
import useCache from '@/hooks/useCache';
import formatters from './formatters';

export default function (tableName, local) {
  const { router } = useRouter();
  const store = useStore();
  const action = ref(null);
  const buzy = ref(false);
  const items = ref([]);
  const total = ref(0);
  const viewPageName = ref('');
  const lastUpdated = ref((new Date()).toISOString());

  const cache = useCache();
  const cacheParams = ref({
    prefix: tableName,
    storage: !local,
    url: !local,
  });
  const searchQuery = cache.create({
    defaultValue: '',
    name: 'query',
    ...cacheParams.value,
    // storage: false,
  });
  const sortBy = cache.create({
    defaultValue: 'id',
    name: 'sortBy',
    ...cacheParams.value,
  });
  const isSortDirDesc = cache.create({
    defaultValue: false,
    name: 'isSortDirDesc',
    ...cacheParams.value,
    boolean: true,
  });
  const paginationPage = cache.create({
    defaultValue: '1',
    name: 'page',
    ...cacheParams.value,
  });
  const paginationPerPage = cache.create({
    defaultValue: '10',
    name: 'perPage',
    ...cacheParams.value,
  });

  const pagination = ref({
    page: paginationPage,
    perPage: paginationPerPage,
  });

  const raw = ref(null);
  const returnRaw = ref(false);
  const getListPushing = ref(false);
  async function getList(params) {
    buzy.value = true;
    const response = await store.dispatch(action.value.name, {
      ...action.value.payload,
      q: searchQuery.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      ...pagination.value,
      ...params,
    });
    if (response.result) {
      raw.value = null;
      if (returnRaw.value) {
        raw.value = response;
        buzy.value = false;
        return;
      }

      const data = response.data.data || response.data.list;

      if (getListPushing.value) items.value = [...items.value, ...data];
      else items.value = data;
      total.value = response.data.total;
    }
    buzy.value = false;
  }

  const perPageOptions = ['10', '25', '50', '100'];
  const totalPages = computed(() => Math.ceil(total.value / pagination.value.perPage));
  const isNeedToUpdateSortNavigation = ref(false);
  let sortNavigationDebounceTimer = null;
  watch([sortBy, isSortDirDesc], () => {
    clearTimeout(sortNavigationDebounceTimer);
    if (sortBy.value) {
      sortNavigationDebounceTimer = setTimeout(() => {
        isNeedToUpdateSortNavigation.value = true;
        sortNavigationDebounceTimer = null;
      }, 5);
    }
  });
  watch(isNeedToUpdateSortNavigation, (value) => {
    if (value) {
      getList();
      isNeedToUpdateSortNavigation.value = false;
    }
  });
  const page = computed(() => pagination.value.page);
  const perPage = computed(() => pagination.value.perPage);
  watch(
    page,
    async () => {
      await nextTick();
      getList();
    },
    { deep: true },
  );
  watch(perPage, () => {
    pagination.value.page = 1;
    getList();
  });

  watch(searchQuery, () => {
    getList();
  });

  const scrollToEnd = () => {
    if (!buzy.value) pagination.value.page += 1;
  };

  const onRowClicked = (entity) => {
    router.push({ name: viewPageName.value, params: { id: entity.id } });
  };

  return {
    action,
    buzy,
    items,
    formatters,
    getList,
    total,
    searchQuery,
    sortBy,
    isSortDirDesc,
    perPageOptions,
    totalPages,
    page,
    perPage,
    pagination,
    scrollToEnd,
    getListPushing,
    onRowClicked,
    viewPageName,
    raw,
    returnRaw,
    lastUpdated,
    cache,
  };
}
