<template>
  <div class="d-flex flex-wrap align-items-center w-100">
    <b-badge
      v-for="entityTag in entity.tags"
      :key="entityTag.id"
      class="entity__tag"
      variant="light-primary"
    >
      {{ entityTag.tag }}
      <FeatherIcon
        v-if="$store.getters['user/permission'](permissionNameDelete)"
        class="cursor-pointer"
        icon="XIcon"
        @click="removeTag(entityTag.id)"
      />
    </b-badge>
    <EditInput
      v-if="$store.getters['user/permission'](permissionNameUpdate)"
      class="tag-new"
      :model="newTag"
      placeholder="Введите тег"
      :options="computedTags"
      :with-select="true"
      :toggle-only-by-icon="false"
      :hide-edit-icon="true"
      style="min-width: 120px;"
      @edit="getTags"
      @input="addTag"
    >
      <b-badge
        id="entity-add-tag-popover"
        variant="light-primary"
        class="entity__tag"
      >
        <FeatherIcon icon="PlusIcon" />
        Добавить
      </b-badge>
    </EditInput>
  </div>
</template>

<script>
import { computed, ref, toRefs } from '@vue/composition-api';
import { BBadge } from 'bootstrap-vue';
import { useStore } from '@/hooks/useStore';
import { getRandomString } from '@/utils/stringHelpers';
import getPermissionName from '@/utils/getPermissionName';
import EditInput from '../editInput/EditInput.vue';
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue';

export default {
  name: 'Tags',
  components: { EditInput, FeatherIcon, BBadge },
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
    isNew: Boolean,
    entityName: String,
  },
  setup(props, { emit }) {
    const store = useStore();
    const { entity, isNew, entityName } = toRefs(props);
    const newTag = ref('');

    const permissionNameUpdate = getPermissionName(entityName.value, 'update');
    const permissionNameDelete = getPermissionName(entityName.value, 'delete');

    const addTagType = computed(() => {
      switch (entityName.value) {
        case 'employee':
          return 'account_user';
        default:
          return entityName.value === 'check_lists' ? 'checklist' : entityName.value;
      }
    });

    const addTagToEntity = async (tag) => {
      if (!tag) return null;
      const response = await store.dispatch('tags/addTag', {
        id: entity.value.id,
        type: addTagType.value,
        tag,
      });
      return response.data;
    };
    const addTag = async (tag) => {
      if (isNew.value) {
        entity.value.tags.push({
          id: getRandomString(),
          type: addTagType.value,
          tag,
        });
        return;
      }
      const returnedTags = await addTagToEntity(tag);
      emit('input', returnedTags);
    };
    const tags = ref([]);
    const getTags = async () => {
      const { result, data } = await store.dispatch('tags/getList', { type: addTagType.value });
      if (result) {
        tags.value = data.map((val) => ({
          label: val.tag,
          value: val.tag,
        }));
      }
    };
    const computedTags = computed(() => {
      const mappedentityTags = entity.value.tags.map((val) => val.tag);
      return tags.value.filter((tag) => !mappedentityTags.includes(tag.value));
    });
    const removeTag = async (tagId) => {
      if (isNew.value) {
        entity.value.tags = entity.value.tags.filter((val) => val.id !== tagId);
        return;
      }
      const { result, data } = await store.dispatch('tags/removeTag', {
        id: entity.value.id,
        type: addTagType.value,
        tagId: String(tagId),
      });
      if (result) {
        emit('input', data);
      }
    };
    return {
      newTag,
      getTags,
      addTag,
      removeTag,
      computedTags,
      permissionNameUpdate,
      permissionNameDelete,
    };
  },
};
</script>

<style lang="sass">
.entity__tag
  margin: 0 5px 6px 0
.tag-new
  flex: 1
  & .edit-input__toggle
    padding-right: 0
  .entity__tag
    margin: 0
</style>
