<template>
  <BCard class="facility-info">
    <b-modal
        :id="`facility-delete-modal-${item.id}`"
        centered
        cancel-variant="outline-secondary"
        ok-variant="danger"
        title="Удаление объекта"
        size="sm"
        ok-title="Удалить"
        cancel-title="Отмена"
        @ok="$emit('deleteEntity')"
    >
      <h5>Вы действительно хотите удалить данный объект?</h5>
    </b-modal>
    <div
      v-if="!isNew"
      class="position-absolute mr-1 mt-1 text-dark"
      style="top: 0; right: 0"
    >
      <b-dropdown
        v-if="$store.getters['user/permission']('facilities:delete')"
        variant="link"
        toggle-class="text-decoration-none p-0 toggler-grey"
        :right="isMobile"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="24"
          />
        </template>
        <b-dropdown-item
            v-b-modal="`facility-delete-modal-${item.id}`"
            @click.prevent
        >
          <FeatherIcon
            icon="Trash2Icon"
            class="mr-1 text-danger"
          />
          <span class="text-danger">
            {{ t('settings.delete') }}
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="facility-info__header">
      <AvatarUpload
        entity="client"
        :avatar-url="item && item.avatar"
        :config="avatarUploadConfig"
        variant="light-success"
        :disabled="isNew || !$store.getters['user/permission']('facilities:update')"
        avatar-size="104px"
        @updated="$emit('needToUpdate')"
      />
      <div class="facility-card-limit">
        <h4 class="m-0">
          <EditInput
            :model="item.name"
            :toggle-only-by-icon="false"
            :placeholder="t('facilities.field.name.placeholder')"
            :readonly="!$store.getters['user/permission']('facilities:update')"
            @input="$emit('updateField', { field: 'name', value: $event })"
          >
            <h4
                :class="'mb-0 cursor-pointer editable-field w-100 overflow-hidden ' +
                'd-block text-ellipsis text-nowrap '
              + (errors.includes('name') ? 'invalid' : '')"
                style="text-overflow: ellipsis"
            >
              {{ item.name || t('facilities.field.name.label') }}
            </h4>
          </EditInput>
        </h4>
        <FacilityTypeSelect
          :item="item"
          class="mt-50 mb-1"
          :readonly="!$store.getters['user/permission']('facilities:update')"
          @updateField="$emit('updateField', $event)"
        />
        <Tags
          v-if="!isNew"
          :entity="item"
          entity-name="facility"
          @input="$emit('needToUpdate')"
        />
      </div>
    </div>
    <div class="facility-info__body info-card mt-1">
      <FacilityClientSelect
        v-if="!noClient && showClient"
        :item="item"
        :is-new="isNew"
        :is-error="errors.includes('client_id')"
        :readonly="!$store.getters['user/permission']('facilities:update')"
        @updateField="$emit('updateField', $event)"
      />
      <FacilityManagerSelect
        :item="item"
        :is-new="isNew"
        :readonly="!$store.getters['user/permission']('facilities:update')"
        @updateField="$emit('updateField', $event)"
      />
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center mr-2">
          <feather-icon
            icon="BriefcaseIcon"
            class="mr-75"
          />
          <span class="font-weight-bold">
            {{ t('facilities.field.contract_number.label') }}
          </span>
          </div>
            <EditInput
              :model="item.contract_number"
              :toggle-only-by-icon="false"
              :placeholder="t('facilities.field.contract_number.placeholder')"
              :readonly="!$store.getters['user/permission']('facilities:update')"
              @input="$emit('updateField', { field: 'contract_number', value: $event })"
            >
              <span
                :class="'mb-0 cursor-pointer editable-field w-100 overflow-hidden ' +
                'd-block text-ellipsis text-nowrap '
                + (errors.includes('contract_number') ? 'invalid' : '')"
                style="text-overflow: ellipsis"
              >
                {{ item.contract_number || t('facilities.field.contract_number.placeholder') }}
              </span>
            </EditInput>
        </div>
      <FacilityContractField
        :item="item"
        :readonly="!$store.getters['user/permission']('facilities:update')"
        @updateField="$emit('updateField', $event)"
      />
    </div>
    <!-- <div
      v-if="!isNew"
      class="facility-info__bottom mt-3 d-flex flex-wrap gap-x-7 gap-y-2"
    >
      <div class="d-flex align-items-center">
        <b-avatar
          variant="light-primary"
          rounded
          size="38px"
        >
          <feather-icon
            icon="TrendingUpIcon"
            size="18"
          />
        </b-avatar>
        <div class="ml-1">
          <h4 class="mb-0">
            {{ monthProfit }}
          </h4>
          <small>{{ t('facilities.field.monthProfit.label') }}</small>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <b-avatar
          variant="light-success"
          rounded
          size="38px"
        >
          <feather-icon
            icon="DollarSignIcon"
            size="18"
          />
        </b-avatar>
        <div class="ml-1">
          <h4 class="mb-0">
            {{ totalProfit }}
          </h4>
          <small>{{ t('facilities.field.totalProfit.label') }}</small>
        </div>
      </div>
    </div> -->
  </BCard>
</template>

<script>
import {
  BCard, BDropdown, BDropdownItem,
} from 'bootstrap-vue';
import {computed, inject, ref, toRefs} from '@vue/composition-api';
import Tags from '@/components/tags/Tags.vue';
import { useI18n } from '@/hooks/useI18n';
import { formatPrice } from '@/utils/price';
import EditInput from '@/components/editInput/EditInput.vue';
import { useStore } from '@/hooks/useStore';
import AvatarUpload from '@/components/avatarUpload/AvatarUpload.vue';
import FacilityTypeSelect from './FacilityTypeSelect.vue';
import FacilityManagerSelect from './FacilityManagerSelect.vue';
import FacilityContractField from './FacilityContractField.vue';
import FacilityClientSelect from './FacilityClientSelect.vue';

export default {
  components: {
    BCard,
    // BAvatar,
    FacilityTypeSelect,
    Tags,
    FacilityManagerSelect,
    FacilityContractField,
    BDropdown,
    BDropdownItem,
    EditInput,
    AvatarUpload,
    FacilityClientSelect,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    noClient: Boolean,
  },
  setup(props) {
    const { item } = toRefs(props);
    const { t } = useI18n();
    const store = useStore();
    const isNew = computed(() => !item.value.id);
    const errors = inject('errors');
    const isMobile = ref(false);

    isMobile.value = store.state.app.isMobile;

    const monthProfit = computed(
      () => !isNew.value
        && formatPrice(
          item.value.income_month.amount,
          {
            rate: 1,
            iso: item.value.income_month.currency,
          },
          0,
          'en',
          false,
          'code',
        ),
    );
    const totalProfit = computed(
      () => !isNew.value
        && formatPrice(
          item.value.income_total.amount,
          // TODO Currency
          {
            rate: 1,
            iso: item.value.income_total.currency,
          },
          0,
          'en',
          false,
          'code',
        ),
    );

    const showClient = computed(() => store.getters['user/account'].show_clients);

    const avatarUploadConfig = {
      updateHandler: (formData) => store.dispatch('facilities/updateFacilityAvatar', { formData, itemId: item.value.id }),
      deleteHandler: (formData) => store.dispatch('facilities/deleteFacilityAvatar', { formData, itemId: item.value.id }),
    };

    return {
      t,
      monthProfit,
      totalProfit,
      isNew,
      avatarUploadConfig,
      errors,
      isMobile,
      showClient,
    };
  },
};
</script>

<style lang="scss">
.facility-info {
  &__header {
    display: flex;
    gap: 20px;
    @media(max-width: 600px) {
      padding-top: 15px;
    }
  }
}
.facility-card-limit {
  max-width: 60%;
  @media (max-width: 600px) {
    max-width: 40%;
  }
}
</style>
