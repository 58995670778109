<template>
  <b-card
    no-body
    class="overflow-hidden requestsMap"
  >
      <template v-if="!coordsIsLoaded">
          <div id="loading-bg">
              <div class="loading-logo">
                  <Logo />
              </div>
              <div class="loading">
                  <div class="effect-1 effects" />
                  <div class="effect-2 effects" />
                  <div class="effect-3 effects" />
              </div>
          </div>
      </template>
      <yandexMap
          v-else
          :coords="center"
          :zoom="10"
          :settings="settings"
          :controls="[]"
          :cluster-options="clusterOptions"
          @map-was-initialized="initMap"
          @boundschange="getBounds"
          @sizechange="getBounds"
      >
          <request-marker
                  v-for="request in requests"
                  :key="'request' + request.id"
                  :request="request"
                  :update-time="updateTime"
                  @markerReady="onMarkerReady"
          >
              <request-map-card
                      :request="request"
                      map
              />
          </request-marker>
          <request-marker
                  :request="currentRequest"
                  :update-time="updateTime"
                  @markerReady="onMarkerReady"
          >
              <request-map-card
                      :request="currentRequest"
                      map
              />
          </request-marker>
          <request-marker
                  v-for="employee in employees"
                  :key="'employee' + employee.id"
                  :request="employee"
                  :type="'employee'"
                  :selected="selectedLocal
                && selectedLocal.length > 0
                && selectedLocal.includes(employee.id)"
                  :update-time="updateTime"
                  @markerReady="onMarkerReady"
          >
              <employee-map-balloon-card
                      :employee="employee"
                      map
              />
          </request-marker>
          <request-marker
                  v-for="serviceman in servicemans"
                  :key="'serviceman' + serviceman.id"
                  :request="serviceman"
                  :type="'employee'"
                  :selected="selectedLocal
                && selectedLocal.length > 0
                && selectedLocal.includes(serviceman.id)"
                  :update-time="updateTime"
                  @markerReady="onMarkerReady"
          >
              <employee-map-balloon-card
                      :employee="serviceman"
                      map
              />
          </request-marker>
          <template v-if="userCoords">
              <ymapMarker
                  :coords="userCoords"
                  :marker-id="'userCoords' + Date.now()"
                  :icon="employeeIcon"
              />
          </template>
      </yandexMap>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import 'leaflet/dist/leaflet.css';
import {inject, nextTick, ref, toRefs, watch } from '@vue/composition-api';
import {useStore} from '@/hooks/useStore';
import {yandexMap, ymapMarker} from "vue-yandex-maps";
import EmployeeMapBalloonCard from "@/components/employeeMapCard/employeeMapBalloonCard.vue";
import Logo from "@core/layouts/components/Logo.vue";
import RequestMarker from './components/RequestMarker.vue';
import RequestMapCard from '../requestCard/RequestMapCard.vue';

export default {
  name: 'RequestsMap',
  components: {
      ymapMarker,
      Logo,
      yandexMap,
      EmployeeMapBalloonCard,
    BCard,
    RequestMarker,
      RequestMapCard,
  },
  props: {
    requests: {
      type: Array,
      default: () => [],
    },
    currentRequest: {
      type: Object,
      default: () => {}
    },
    popupToOpen: {
      type: Object,
      default: null,
    },
    employees: {
      type: Array,
      default: () => [],
    },
    servicemans: {
      type: Array,
      default: () => [],
    },
    openedTicket: {
      type: [String, Number],
      default: null,
    },
    centerOnRequest: {
      type: Boolean,
      default: false
    },
      openedEmployeeModel: {
          type: Array,
          default: () => [],
      }
  },

  setup(props, { emit }) {
    const attribution = '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';
    const {
        requests,
        openedTicket,
        centerOnRequest,
        currentRequest,
        openedEmployeeModel,
        employees
    } = toRefs(props);
    const store = useStore();
    const initialCoordinates = ref(store.state.app.initialMapCoordinates);
    const center = centerOnRequest.value
    && typeof currentRequest.value.facility !== 'undefined'
    && currentRequest.value.facility
        ? ref([currentRequest.value.facility.lat, currentRequest.value.facility.lon])
        : ref(initialCoordinates);
    const selectedLocal = inject('selectedLocal');

      const userCoords = ref(null);
      const coordsIsLoaded = ref(false);
      const currentCenter = async () => {
          const userGeoCoords = await store.dispatch('app/getCoords');

          if (JSON.stringify(userGeoCoords) !== JSON.stringify(center.value)) {
              userCoords.value = userGeoCoords;

              if (employees.value.length === 1 && employees.value[0].last_geo_location) {
                  coordsIsLoaded.value = true;
                  return;
              }
              center.value = userGeoCoords;
          }
          coordsIsLoaded.value = true;
      };
      currentCenter();

      if (employees.value.length === 1 && employees.value[0].last_geo_location) {
          center.value = [
              employees.value[0].last_geo_location.lat,
              employees.value[0].last_geo_location.lng,
          ];
      }

    const mapRef = ref(null);
    const map = ref(null);
    const onMapReady = () => {
      window.dispatchEvent(new Event('resize'));
      map.value = mapRef.value.mapObject;
    };

    // const reqeustsFeatures = ref(null);
    const markerToOpen = ref(null);
    const requestToOpen = ref(null);
    const updateTime = ref(null);
    const onMarkerReady = (refData) => {
        const latLng = refData[1].value;
      if (!latLng) {
        return;
      }
      if ((requestToOpen.value?.facility === undefined || requestToOpen.value?.facility === null)
          && (requests.value[0]?.facility === undefined || requests.value[0]?.facility === null)) {
        return;
      }
      const { lat, lon } = requestToOpen.value?.facility || requests.value[0]?.facility;
      if (latLng[0] === lat && latLng[1] === lon) markerToOpen.value = refData;
    };

    watch(openedTicket, async (id) => {
      requestToOpen.value = requests.value.find((i) => i.id === id);
      await nextTick();
      updateTime.value = Date.now();
    });

    // watch(markerToOpen, (refData) => {
    //   // eslint-disable-next-line no-underscore-dangle
    //   center.value = refData[1].value;
    // });

    watch(openedEmployeeModel, (val) => {
        if (val !== null && val.length === 1
            && val[0].last_geo_location
            && typeof val[0].last_geo_location.lat !== "undefined"
            && typeof val[0].last_geo_location.lng !== "undefined") {
            center.value = [
                val[0].last_geo_location.lat,
                val[0].last_geo_location.lng,
            ];
        }
    });

      const yandexMap = ref(null);
      const getBounds = async () => {
          const world = yandexMap.value.getGlobalPixelCenter();
          const container = yandexMap.value.container.getSize();
          const topLeftX = Math.floor((world[0] - container[0] / 2) / 256)
              * (2 ** (23 - yandexMap.value.getZoom()));
          const topLeftY = Math.floor((world[1] - container[1] / 2) / 256)
              * (2 ** (23 - yandexMap.value.getZoom()));
          const bottRightX = Math.ceil((world[0] + container[0] / 2) / 256)
              * (2 ** (23 - yandexMap.value.getZoom()));
          const bottRightY = Math.ceil((world[1] + container[1] / 2) / 256)
              * (2 ** (23 - yandexMap.value.getZoom()));

          const coordData = {topLeftX, topLeftY, bottRightX, bottRightY};
          emit('mapChange', coordData);
      };
      const initMap = async (map) => {
          yandexMap.value = map;
          await getBounds();
      };

      const token = process.env.VUE_APP_YANDEX_API_KEY;

      const settings = {
          apiKey: token,
          lang: 'ru_RU',
          coordorder: 'latlong',
          enterprise: false,
          version: '2.1'
      };

      const clusterOptions = ref({
          1: {
              clusterDisableClickZoom: false,
              clusterOpenBalloonOnClick: false,
              preset: 'islands#invertedVioletClusterIcons',
          },
      });

      const employeeIcon = ref({
          layout: 'default#imageWithContent',
          imageHref: '/map/employees.svg',
          imageSize: [43, 43],
          imageOffset: [-23, -43],
          content: '',
          contentOffset: [0, 0],
          contentLayout: ''
      });

    return {
      attribution,
      center,
        settings,
        clusterOptions,

      map,
      mapRef,
      onMapReady,
      onMarkerReady,
      markerToOpen,
      updateTime,
      selectedLocal,

        coordsIsLoaded,
        userCoords,
        employeeIcon,

        initMap,
        getBounds,
    };
  },
};
</script>

<style lang="sass">
@import "~@/assets/scss/utils.scss"

.requestsMap
  height: calc(85vh - 144px)
  position: sticky
  top: 114px
  &-popup
    & .leaflet-popup-content-wrapper
      padding: 0
      & .leaflet-popup-content
        margin: 0
        width: 350px !important
        max-width: 70vw !important
        & .requestCard
          width: 100%
          max-width: 70vw
          margin-bottom: 0
          border-radius: 12px
          overflow: hidden
@include dark-theme
  .marker-cluster span
    color: #4b4b4b
</style>
