<template>
  <div class="employee-requests-cards">
    <request-card
      v-for="request in requests"
      :key="request.id"
      :request="request"
      @clicked="emit('clicked', request.id)"
    />
  </div>
</template>

<script>
import {ref, toRefs, watch} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import RequestCard from '../requestCard/RequestCard.vue';

export default {
  name: 'EmployeeRequestsCards',
  components: { RequestCard },
  props: {
    employeeId: {
      type: [String, Number],
      default: null,
    },
    inProcess: {
      type: Boolean,
      default: true,
    },
    payload: Object,
  },

  setup(props, { emit }) {
    const { employeeId, payload } = toRefs(props);
    const store = useStore();
    const requests = ref([]);
    const getRequestsStore = (payload) => store.dispatch('requests/find', { employee_id: employeeId.value, ...payload });
    const getRequests = async () => {
      emit('requestsStartLoading');
      const { result, data } = await getRequestsStore(payload.value || {});
      if (result) {
        requests.value = data.data;
      } else requests.value = [];

      emit('requestsLoaded', requests.value);
    };

    watch(payload, () => getRequests());

    if (employeeId) {
      getRequests();
    }

    return {
      requests,
      getRequestsStore,
      getRequests,
      emit,
    };
  },
};
</script>

<style lang="sass">
.employee-requests-cards
  & .requestCard
    margin-bottom: 9px !important
    cursor: pointer
  & .requestCard:last-child
    margin-bottom: 0 !important
</style>
