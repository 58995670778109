<template>
  <div class="m-2 scheduler">
    <SchedulerItem
      v-for="item in schedulers"
      :key="item.id"
      :item="item"
      @needToUpdate="needToUpdate"
      @openSchedule="onOpenSchedule"
    />

    <BButton
      v-if="$store.getters['user/permission']('schedule:create') &&
        $store.getters['user/permission']('facilities:update')"
      v-b-modal.scheduleEditModal
      variant="outline-primary"
      class="mt-1"
    >
      Добавить
    </BButton>

    <b-modal
      id="scheduleEditModal"
      ref="scheduleEditModal"
      size="xl"
      hide-footer
      title="Плановая заявка"
      body-class="m-0 p-0"
      centered
    >
      <SchedulerAddPage
        :id="scheduleEditModalId"
        :pre-select-facility="byFacilityId"
        class="schedulerDialog"
        :title="false"
        @input="onScheduleInput"
        @close="onScheduleClose"
      />
    </b-modal>
  </div>
</template>

<script>
import { toRefs, ref } from '@vue/composition-api';
import { BModal, BButton } from 'bootstrap-vue';
import { getSchedulers } from '@/store/scheduler/scheduler.api';
import SchedulerAddPage from '@/views/scheduler/SchedulerAddPage.vue';
import SchedulerItem from './SchedulerItem.vue';

export default {
  components: {
    SchedulerItem,
    BModal,
    SchedulerAddPage,
    BButton,
  },
  props: {
    byFacilityId: Number,
  },
  setup(props) {
    const { byFacilityId } = toRefs(props);

    const schedulers = ref([]);
    const getSchedulersList = async () => {
      schedulers.value = (
          await getSchedulers({
            facility_id: byFacilityId.value,
          })
        )?.data?.response?.data || [];
    };
    getSchedulersList();

    const needToUpdate = () => {
      getSchedulersList();
    };

    const scheduleEditModal = ref(null);
    const scheduleEditModalId = ref(null);
    const onOpenSchedule = (value) => {
      scheduleEditModal.value.toggle();
      scheduleEditModalId.value = value;
    };
    const onScheduleClose = () => {
      scheduleEditModal.value.hide();
      scheduleEditModalId.value = null;
    };
    const onScheduleInput = () => {
      onScheduleClose();
      needToUpdate();
    };
    const addSchedule = () => {
      scheduleEditModal.value.show();
    };

    return {
      schedulers,
      getSchedulersList,
      needToUpdate,
      onOpenSchedule,
      scheduleEditModal,
      scheduleEditModalId,
      onScheduleClose,
      onScheduleInput,
      addSchedule,
    };
  },
};
</script>

<style lang="sass">
.schedulerDialog > .card
  margin-bottom: 0
.scheduler
  // max-width: 1124px
</style>
