<template>
  <b-card
    class="facility-props"
    no-body
  >
    <AppCollapse>
      <AppCollapseItem
        v-if="!noContacts"
        title=""
        :is-visible.sync="isContactsDropdownVisible"
        class="collapse-no-body"
        no-body
      >
        <template #header>
          <div class="d-flex w-100 align-items-center justify-content-between pl-1 pt-1 pb-1 pr-1">
            <h4 class="m-0">
              Контакты
            </h4>
            <FeatherIcon
              v-if="$store.getters['user/permission']('facilities:update')"
              id="addClientIcon"
              icon="PlusIcon"
              size="24"
              @click.stop="addContact"
            />
          </div>
        </template>
        <Contacts
          :contacts="item.contacts"
          entity-name="facility"
          :entity-id="item.id"
          :add-contact.sync="newContactVisible"
          @needToUpdate="$emit('needToUpdate')"
        />
      </AppCollapseItem>

      <AddressCollapse
        :value="item.address"
        :facility-id="item.id"
        :facility="item"
        :lat="item.lat"
        :lng="item.lon"
        :readonly="!$store.getters['user/permission']('facilities:update')"
        @needToUpdate="$emit('needToUpdate')"
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
    </AppCollapse>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api';
import {
  BCard,
} from 'bootstrap-vue';
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue';
import AddressCollapse from '@/components/address/AddressCollapse.vue';
import Contacts from '../contacts/Contacts.vue';

export default {
  components: {
    BCard,
    AppCollapse,
    AppCollapseItem,
    Contacts,
    AddressCollapse,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    noContacts: Boolean,
  },
  setup() {
    const isContactsDropdownVisible = ref(true);
    const newContactVisible = ref(false);
    const addContact = () => {
      newContactVisible.value = true;
      isContactsDropdownVisible.value = true;
    };

    return {
      isContactsDropdownVisible,
      newContactVisible,
      addContact,
    };
  },
};
</script>
