<template>
  <BCard
    class="scheduler-item shadow-card pointer"
    @click="$emit('openSchedule', item.id)"
  >
    <div
    class="scheduler-content"
    >
      <div>{{ item.work_type_name }}</div>
      <div class="d-flex gap-1">
        <span>Повтор: </span>
        <div v-if="item.repeat === 'week' && item.repeat_days">
          <span v-if="!!item.repeat_days.find((item) => item === 1)">Пн</span>
          <span v-if="!!item.repeat_days.find((item) => item === 2)"> ВТ</span>
          <span v-if="!!item.repeat_days.find((item) => item === 3)"> Ср</span>
          <span v-if="!!item.repeat_days.find((item) => item === 4)"> Чт</span>
          <span v-if="!!item.repeat_days.find((item) => item === 5)"> Пт</span>
          <span v-if="!!item.repeat_days.find((item) => item === 6)"> Сб</span>
          <span v-if="!!item.repeat_days.find((item) => item === 7)"> Вс</span>
        </div>
        <div
            v-else
            class="d-flex"
        >
          {{ item.repeat_every }}
          {{ item.repeat === 'year' ? 'год' : item.repeat === 'month' ? 'мес.' : 'день' }}
        </div>
      </div>
      <div>{{ startDateLabel }} - {{ endDateLabel }}</div>
    </div>
    <b-dropdown
      v-if="$store.getters['user/permission']('schedule:delete')"
      right
      variant="link"
      toggle-class="text-decoration-none p-0"
      no-caret
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="24"
        />
      </template>
      <b-dropdown-item
        @click.stop="openDeleteModal"
      >
        <FeatherIcon
          icon="Trash2Icon"
          class="mr-1 text-danger"
        />
        <span class="text-danger">
          {{ t('settings.delete') }}
        </span>
      </b-dropdown-item>
    </b-dropdown>

    <b-modal
      ref="deleteModal"
      centered
      title="Удаление плановой заявки"
      size="sm"
      ok-title="Удалить"
      cancel-title="Отмена"
      @ok="schedulerDelete()"
    >
      <h5>Вы действительно хотите удалить эту плановою заявку?</h5>
    </b-modal>
  </BCard>
</template>

<script>
import { BCard, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { toRefs, computed, ref } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import { deleteScheduler } from '@/store/scheduler/scheduler.api';

export default {
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
  },
  props: {
    item: Object,
  },
  setup(props, { emit }) {
    const { item } = toRefs(props);
    const { t } = useI18n();

    const localeConf = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const startDateLabel = computed(() => new Date(item.value.start_date).toLocaleString('ru-RU', localeConf));
    const endDateLabel = computed(() => new Date(item.value.end_date).toLocaleString('ru-RU', localeConf));

    const schedulerDelete = async () => {
      deleteScheduler(item.value.id).then(() => {
        emit('needToUpdate');
      });
    };

    const deleteModal = ref(null);
    const openDeleteModal = () => {
      deleteModal.value.show();
    };

    return {
      t,
      startDateLabel,
      endDateLabel,
      schedulerDelete,
      openDeleteModal,
      deleteModal,
    };
  },
};
</script>

<style lang="scss">
.scheduler {
  &-item {
    margin-bottom: 15px;
    .card-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.scheduler-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 2px;
  }
}
</style>
