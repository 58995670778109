import { getTimeDifference } from '../../utils/time';

export default {
  dateFormatterRaw: (value) => (value
    ? new Date(value).toLocaleString('ru-RU', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    : null),
  dateFormatter: (value) => getTimeDifference(value, true),
  nameFormatter: (value) => (value.firstname && value.surname ? `${value.firstname} ${value.surname}` : null),
};
