<template>
  <b-card
    class="requestCard"
    @click="emit('clicked', request.id)"
    @mouseover="showRouteButton"
    @mouseleave="hideRouteButton"
  >
      <div
      class="d-flex gap-1"
      style="justify-content: space-between"
>
          <div class="flex-shrink-1">
              <BLink
                      :to="`/requests/${request.id}`"
              >
                  <h6 style="margin-bottom: 0">
                      {{ request.name || `Заявка #${request.number}` }}
                  </h6>
              </BLink>
          </div>
          <div>
              <b-badge
                      v-if="request.type"
                      class="request-type-badge"
                      :style="getTypeColor(request.type)"
              >
                  {{ request.type.name }}
              </b-badge>
              <b-badge
                      v-else
                      class="text-muted"
              >
                  Без типа
              </b-badge>
          </div>
      </div>
      <div
        class="d-flex"
        style="margin-top: 5px;justify-content: space-between"
      >
          <div
            class="d-flex align-items-center flex-shrink-1 w-100"
          >
              <feather-icon
                      icon="BriefcaseIcon"
                      class="mr-50 second-text"
              />
              <small
                      v-if="request.facility"
                      class="second-text"
              >
                  {{ request.facility.name }}
              </small>
          </div>
          <div class="second-text">
              {{ request.work_type.name }}
          </div>
      </div>
      <div
        class="d-flex"
        style="margin-top: 5px;
        justify-content: space-between"
>
            <small
                v-if="request.facility"
                class="d-flex flex-shrink-1 text-muted"
            >
                {{ request.facility.address }}
            </small>
            <div
v-if="!showingRouteButton"
                 class="second-text"
                 style="white-space: nowrap"
>
                {{ dateFormatted }}
            </div>
            <div v-if="map && showingRouteButton">
                <b-button
                    variant="primary"
                    :href="linkToYandex"
                    target="_blank"
                >
                    <feather-icon icon="SendIcon" />
                    {{ t('request.route') }}
                </b-button>
        </div>
    </div>
  </b-card>
</template>

<script>
import { computed, ref, toRefs } from '@vue/composition-api';
import {
  BCard, BBadge, BButton, BLink,
} from 'bootstrap-vue';
import { useI18n } from '@/hooks/useI18n';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import { formatDate } from '@/utils/time';
import requestTypeColor from '@/utils/requestTypeColor';

export default {
  name: 'RequestCard',
  components: {
    BCard,
    BBadge,
    BButton,
    FeatherIcon,
    BLink,
  },
  props: {
    request: {
      type: Object,
      default: () => {},
    },
    map: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { request, map } = toRefs(props);

    const showingRouteButton = ref(false);

    const linkToYandex = ref(null);
    if (request.value.facility && request.value.facility.lat && request.value.facility.lon) {
      const { lat, lon } = request.value.facility;
      linkToYandex.value = `https://yandex.ru/maps/?origin=jsapi21&ll=${lon},${lat}&z=15&l=map&from=api-maps&rtt=auto&rtext=~${lat},${lon}`;
    }

    const showRouteButton = () => {
      if (map.value) showingRouteButton.value = true;
    };
    const hideRouteButton = () => {
      if (map.value) showingRouteButton.value = false;
    };

    const statusColor = computed(() => {
      if (request.value.status.color === 'crimson') {
        return 'warning';
      }
      return '';
    });
    const dateFormatted = formatDate(request.value.deadline_at, {
      withYear: false,
      withHours: true,
      withMinutes: true,
    });

    const getTypeColor = (type) => requestTypeColor(type);

    return {
      t,
      emit,

      showingRouteButton,
      showRouteButton,
      hideRouteButton,
      linkToYandex,

      statusColor,
      dateFormatted,
      getTypeColor,
    };
  },
};
</script>

<style lang="sass">
@import "~@/assets/scss/utils.scss"
.card .card.requestCard
  box-shadow: $box-shadow !important
  .request-type-badge
    color: #82868B

@include dark-theme
  .card .card.requestCard
    background-color: $theme-dark-body-bg
    box-shadow: $theme-dark-box-shadow !important
    .request-type-badge
      color: #ffffff
    .second-text
      color: #b8c2cc
    .leaflet-popup-tip
      background: #283046
</style>
