// import { useStore } from '@/hooks/useStore';

export default function () {
  // const { t } = useI18n();

  const getError = (errors) => {
    const firstErrorKey = Object.keys(errors)[0];
    const error = errors[firstErrorKey][0];
    return error;
  };

  return {
    getError,
  };
}
