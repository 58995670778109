export const formatPrice = (
  amount,
  { rate, iso },
  fractionDigits = 2,
  locale = 'ru',
  disableSymbol = false,
  currencyDisplay = 'narrowSymbol',
) => new Intl.NumberFormat(locale, {
  style: disableSymbol ? 'decimal' : 'currency',
  currencyDisplay,
  currency: iso,
  maximumFractionDigits: fractionDigits,
}).format(amount * rate);
