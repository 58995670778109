<template>
  <div class="employee-small-card w-100">
    <b-media
      vertical-align="center"
      class="w-100 d-flex align-items-start"
    >
      <template #aside>
        <b-link
            :to="{ name: 'employee-view', params: { employeeId: employee.id } }"
        >
          <b-avatar
            class="employee-small-card-avatar"
            size="32"
            :src="employee.avatar"
            :text="avatarText(`${employee.firstname} ${employee.surname}`)"
          />
        </b-link>
      </template>

      <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex flex-column text-ellipsis overflow-hidden">
              <b-link
                      :to="{ name: 'employee-view', params: { employeeId: employee.id } }"
                      class="font-weight-bold d-block text-nowrap
            text-ellipsis overflow-hidden text-dark mt-25"
                      style="text-overflow: ellipsis"
              >
                  {{ fullName }}
              </b-link>
              <small>{{ userRole }}</small>
              <div
                      v-if="withWorkload"
                      style="max-width: 100px"
                      class="mt-50 mb-1"
              >
                  <employee-workload :workload="employee.workload" />
              </div>
          </div>
        <div v-if="withRequests">
          <b-avatar
            class="employee-small-card-toggleRequests"
            button
            variant="transparent"
            @click.stop="toggleRequests"
          >
            <feather-icon
              v-if="!requestsOpenedLocal"
              icon="ChevronDownIcon"
            />
            <feather-icon
              v-if="requestsOpenedLocal"
              icon="ChevronUpIcon"
            />
          </b-avatar>
        </div>
      </div>
    </b-media>

    <div
      v-if="withRequests && requestsOpenedLocal"
      class="py-1"
    >
      <b-overlay
        :show="inProcess"
        opacity="0.8"
        variant="transparent"
      >
        <div
          v-if="requests && requests.length === 0"
          class="text-muted"
        >
          {{ t('employees.requests.notFound') }}
        </div>
        <employee-requests-cards
          :employee-id="employee.id"
          @requestsStartLoading="requestsStartLoading"
          @requestsLoaded="requestsLoaded"
        />
        <b-overlay />
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BMedia,
  BAvatar,
  BLink,
  BOverlay,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import {computed, ref, toRefs} from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import EmployeeWorkload from '../employeeWorkload/EmployeeWorkload.vue';
import EmployeeRequestsCards from '../employeeRequestsCards/EmployeeRequestsCards.vue';

export default {
  name: 'EmployeeSmallCard',
  components: {
    BMedia,
    BAvatar,
    BLink,
    EmployeeWorkload,
    BOverlay,
    EmployeeRequestsCards,
  },
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
    withWorkload: {
      type: Boolean,
      default: false,
    },
    withPatronymic: {
      type: Boolean,
      default: false,
    },
    withRequests: {
      type: Boolean,
      default: false,
    },
    requestsOpened: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { requestsOpened, employee, withPatronymic } = toRefs(props);
    const requestsOpenedLocal = ref(requestsOpened.value);

    const inProcess = ref(true);
    const requests = ref(null);

    const fullName = withPatronymic.value
          ? `${employee.value.surname} ${employee.value.firstname} ${employee.value?.patronymic === null ? '' : employee.value?.patronymic}`
          : `${employee.value.firstname} ${employee.value.surname}`;

    const requestsStartLoading = () => {
      inProcess.value = true;
    };
    const requestsLoaded = (items) => {
      requests.value = items;
      emit('requestsLoaded', items);
      inProcess.value = false;
    };

    const toggleRequests = () => {
      requestsOpenedLocal.value = !requestsOpenedLocal.value;
      if (requestsOpenedLocal.value) {
        emit('requestsOpened', employee.value.id);
      } else {
        emit('requestsClosed', employee.value.id);
      }
    };

    const userRole = computed(() => {
        if (employee.value.role) {
            return employee.value.role.description;
        }

        return '';
    });

    return {
      t,
      avatarText,
      requestsOpenedLocal,
      fullName,

      inProcess,
      toggleRequests,
      requests,
      requestsStartLoading,
      requestsLoaded,

        userRole,
    };
  },
  watch: {
    requestsOpened: {
      handler(value) {
        if (this.requestsOpenedLocal !== value) {
          this.requestsOpenedLocal = value;
        }
      },
    },
  },
};
</script>

<style lang="sass">
@import 'src/@core/scss/base/core/colors/palette-variables.scss'
.employee-small-card
  .media-body
    width: 80%
  &-avatar
    background-color: transparentize($warning, 0.88)
    color: var(--red)
    font-weight: 700
  &-toggleRequests
    color: var(--secondary)
</style>
