<template>
  <div>
    <span
      v-if="status === 'expired'"
      class="text-danger"
    >{{ text }}</span>
    <span
      v-if="status === 'ending'"
      class="text-success"
    >{{ text }}</span>
    <span v-if="status === 'active'">{{ text }}</span>
  </div>
</template>

<script>
import { toRefs, computed, ref } from '@vue/composition-api';
import { formatDateShort } from '@/utils/time';

export default {
  props: {
    date: String,
  },
  setup(props) {
    const { date } = toRefs(props);

    const dateRaw = computed(() => new Date(date.value));
    const currentDateRaw = new Date();

    const curDateTime = Date.now();
    const endDateTime = dateRaw.value.getTime();
    const dateDifference = curDateTime - endDateTime;

    const offset = 604800000;
    const text = ref('');
    const status = computed(() => {
      if (endDateTime < curDateTime) {
        text.value = 'Просрочено';
        return 'expired';
      }
      if (Math.abs(dateDifference) < offset) {
        const endDay = dateRaw.value.getDay();
        const curDay = currentDateRaw.getDay();
        if (endDay === curDay) {
          text.value = 'Сегодня';
          return 'ending';
        }
        if (endDay === curDay + 1) {
          text.value = 'Завтра';
          return 'ending';
        }
      }

      text.value = formatDateShort(dateRaw.value, { withHours: true, withMinutes: true });
      return 'active';
    });

    return {
      status,
      text,
    };
  },
};
</script>
