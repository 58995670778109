<template>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        v-if="total > 10"
        cols="12"
        sm="6"
        class="
          d-flex
          align-items-center
          justify-content-center
          justify-content-sm-start
          text-muted
        "
      >
        <span class="mr-1">{{ t('app.table.show-by') }}</span>
        <b-form-group style="width: 90px; margin: 0">
          <app-select
            v-model="pagination.perPage"
            style="width: 100%"
            :searchable="false"
            label="title"
            :clearable="false"
            :options="perPageOptions"
          />
        </b-form-group>
        <span class="ml-1">
          {{ t('app.table.of', { total: total }) }}
        </span>
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-if="totalPages > 1"
          v-model="pagination.page"
          :total-rows="total"
          :per-page="pagination.perPage"
          first-number
          last-number
          :limit="isMobile ? 1 : 5"
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BPagination, BFormGroup,
} from 'bootstrap-vue';
import { useI18n } from '@/hooks/useI18n';
import { ref } from "@vue/composition-api";
import { useStore } from "@/hooks/useStore";
import AppSelect from '../appSelect/AppSelect.vue';

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BFormGroup,
    AppSelect,
  },
  props: {
    total: Number,
    pagination: Object,
    totalPages: Number,
    perPageOptions: Array,
  },
  setup() {
    const { t } = useI18n();
    const isMobile = ref(false);
    const store = useStore();

    isMobile.value = store.state.app.isMobile;

    return {
      t,
      isMobile,
    };
  },
};
</script>
