<template>
  <BLink
    :to="`/requests/${item.id}`"
    class="text-base text-hover-base"
  >
    <BCard class="shadow-card request mb-1">
      <div class="d-flex justify-content-between request-row gap-2 mb-50">
        <div class="flex-fill">
          <span
            v-if="item.name"
            class="font-weight-bold request-name"
          >
            {{ item.name }}
          </span>
          <span
            v-else-if="item.number"
            class="text-muted"
          >Заявка #{{ item.number }}</span>
          <span
            v-else
            class="text-muted"
          >Без названия</span>
        </div>
        <div>{{ startedFormatted }}</div>
      </div>

      <div class="d-flex justify-content-between request-row gap-2 mb-50">
        <div>
          <BBadge
            v-if="item.type"
            variant="secondary"
            :style="getTypeColor(item.type)"
          >
            {{ item.type.name }}
          </BBadge>
          <span
            v-else
            class="text-muted"
          >
            Не указан
          </span>
        </div>
        <div>
          <template v-if="item.work_type">
            {{ item.work_type.name }}
          </template>
          <span
            v-else
            class="text-muted"
          >
            Не указан
          </span>
        </div>
      </div>

      <div class="d-flex justify-content-between request-row gap-2 mb-50">
        <BLink
          v-if="item.facility"
          :to="`/facility/${item.facility.id}`"
          class="d-flex gap-1 align-items-center text-base"
        >
          <feather-icon icon="BriefcaseIcon" />
          {{ item.facility.name }}
        </BLink>
        <span
          v-else
          class="text-muted"
        >
          Без объекта
        </span>
      </div>

      <div class="d-flex justify-content-between request-row gap-2">
        <div>
          <BLink
            v-if="item.manager"
            :to="`/employee/${item.manager.id}`"
            class="d-flex gap-1 align-items-center text-base"
          >
            <feather-icon icon="UserIcon" />
            {{ item.manager.firstname }} {{ item.manager.surname }}
          </BLink>
          <span
            v-else
            class="text-muted"
          >
            Выбрать ответственного
          </span>
        </div>
        <div>
          <template v-if="item.deadline_at">
            {{ deadlineFormatted }}
          </template>
          <template v-else>
            Без даты
          </template>
        </div>
      </div>
    </BCard>
  </BLink>
</template>

<script>
import { toRefs, computed, ref } from '@vue/composition-api';
import { BCard, BBadge, BLink } from 'bootstrap-vue';
import { formatDateShort, getTimeDifference } from '@/utils/time';
import { useStore } from '@/hooks/useStore';

export default {
  components: {
    BCard,
    BBadge,
    BLink,
  },
  props: {
    item: Object,
  },
  setup(props) {
    const { item } = toRefs(props);
    const store = useStore();

    const startedFormatted = computed(() => formatDateShort(item.value.started_at));
    // eslint-disable-next-line max-len
    const deadlineFormatted = computed(() => getTimeDifference(item.value.deadline_at, true));

    const types = ref([]);
    const getTypes = async () => {
      types.value = (await store.dispatch('requests/types'))?.data;
    };
    getTypes();
    const getTypeColor = (type) => {
      let color;
      if (type.name === 'Обычная') color = '108, 117, 125';
      if (type.name === 'Аварийная') color = '234, 84, 85';
      if (type.name === 'Плановая') color = '40, 199, 111';
      if (type.name === 'Гарантийная') color = '255, 159, 67';
      return `background-color: rgba(${color}, .12); color: #82868B;`;
    };

    return {
      startedFormatted,
      deadlineFormatted,
      types,
      getTypeColor,
    };
  },
};
</script>

<style lang="sass">
.dark-layout
  & .request
    color: #b8c2cc
    & a
      color: #b8c2cc
.request
  min-width: 348px
  font-size: 12px
  &-name
    font-size: 14px
</style>
