<template>
    <div>
      <BCard no-body>
        <BOverlay :show="buzy">
          <b-tabs
            v-model="tabsModel"
            no-nav-style
            :class="`tabs icons ${noSelectView && 'noSelectView'}`"
            lazy
          >
            <b-tab>
              <template
                v-if="!noSelectView"
                #title
              >
                <feather-icon icon="ListIcon" />
              </template>

              <BTable
                responsive
                :items="items"
                :fields="customTableFields"
                :no-local-sorting="true"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                class="requestsTable"
                hover
                show-empty
                empty-text="Ничего не найдено"
                @row-clicked="onRowClicked"
              >
                <template #cell()="{field, value}">
                  <template v-if="field.empty && !value">
                    <span class="text-muted">{{ field.empty }}</span>
                  </template>
                  <template v-else-if="field.displayParam">
                    <span>{{ value[field.displayParam] }}</span>
                  </template>
                  <template v-else-if="typeof field.formatter === 'string'">
                    {{ formatters[field.formatter](value) }}
                  </template>
                  <template v-else>
                    {{ value }}
                  </template>
                </template>

                <template #cell(type)="{field, value}">
                  <template v-if="value && value.id">
                    <BBadge
                      class="request-type-badge"
                      :style="getTypeColor(value)"
                    >
                      {{ value.name }}
                    </BBadge>
                  </template>
                  <template v-if="field.empty && !value">
                    <span class="text-muted">{{ field.empty }}</span>
                  </template>
                </template>
                <template #cell(status)="{field, value}">
                  <template v-if="value && value.id">
                    <BBadge
                      class="request-status-badge"
                      :style="getStatusColor(value.id)"
                    >
                      {{ value.name }}
                    </BBadge>
                  </template>
                  <template v-if="field.empty && !value">
                    <span class="text-muted">{{ field.empty }}</span>
                  </template>
                </template>
                  <template #cell(employees)="{field, value}">
                      <div
v-for="person in value"
:key="person.id"
>
{{ person.firstname }} {{ person.surname }}
</div>
                </template>

                <template #cell(name)="{item, field, value}">
                  <div
                    v-if="value"
                    class="font-weight-bolder"
                  >
                    {{ value }}
                  </div>
                  <div
                    v-else
                    class="text-muted"
                  >
                    {{ field.empty }}
                  </div>
                  <div
                    v-if="item.number"
                    class="text-muted"
                  >
                    #{{ item.number }}
                  </div>
                </template>

                <template #cell(deadline_at)="{value}">
                  <Deadline :date="value" />
                </template>
              </BTable>

              <EntityTablePagination
                :total="total"
                :total-pages="totalPages"
                :pagination="pagination"
                :per-page-options="perPageOptions"
              />
            </b-tab>
            <b-tab
              v-if="gridView"
              class="pr-2"
            >
              <template
                v-if="!noSelectView"
                #title
              >
                <feather-icon icon="GridIcon" />
              </template>
            </b-tab>
            <b-tab
              v-if="mapView"
              class="pr-2"
            >
              <template
                v-if="!noSelectView"
                #title
              >
                <feather-icon icon="MapPinIcon" />
              </template>

              <employees-requests-map
                :selectable="selectable"
                :employee="employeeData"
                :payload="payload"
                :force-update-list="forceUpdateList"
                style="padding-left: 1.5rem"
              />
            </b-tab>

            <template #tabs-end>
              <div
                :class="{'ml-2' : !isMobile, 'w-100' : isMobile}"
                :style="(isMobile ? 'flex: auto' : '')"
              >
                <template v-if="!isMobile">
                  <div class="d-flex align-items-center flex-wrap gap-1">
                    <div
class="d-flex align-items-center
                      flex-fill flex-wrap gap-1"
>
                      <EntityTableSearch
                        :query.sync="searchQuery"
                      />
                        <div
                          v-if="withHideFinishedFilter && tabsModel !== 1"
                          class="mx-2"
                        >
                          <b-form-checkbox
                            v-model="hideFinishedFilter"
                            name="hideFinishedFilter"
                          >
                            Скрыть завершённые
                          </b-form-checkbox>
                        </div>
                      </div>
                      <div
                        v-if="datepicker && mapView"
                        style="color: #7367F0; text-decoration: underline;"
                      >
                        <div class="d-flex gap-2 align-items-end">
                          <div
                            style="cursor: pointer"
                            @click="selectDateForDatepicker('today')"
                          >
                            Сегодня
                          </div>
                          <div
                            style="cursor: pointer"
                            @click="selectDateForDatepicker('tomorrow')"
                          >
                            Завтра
                          </div>
                          <div
                            style="cursor: pointer;
                            display: flex; gap: 12px; align-items: flex-end"
                          >
                            <CalendarRange @update="updateRange" />
                            <div
                              v-if="dateFrom"
                              @click="setDatePreset(undefined, undefined)"
                            >
                              Сбросить фильтр
                            </div>
                            <!-- <Calendar
                              icon="CalendarIcon"
                              only-icon
                              right
                              icon-size="24"
                              :offset="16"
                              style="margin: 0"
                              @updateField="selectDateForDatepicker"
                              /> -->
                          </div>
                        </div>
                      </div>
                      <!-- (creatable && creatableAllowed) &&
                        $store.getters['user/permission']('requests:create') -->
                      <sorting-configure
v-show="tabsModel !== 1"
                              :list="fields"
                              @changeSorting="(e) => fields = e"
/>
                      <BButton
                        v-if="creatable
                        && $store.getters['user/permission']('requests:create')
                        && creatableAllowed"
                        style="flex-0"
                        variant="primary"
                        :to="{ path: '/requests/add', query: creatableParams }"
                      >
                        Добавить
                      </BButton>
                      <!-- <BButton
                        style="flex-0"
                        class="m-2"
                        variant="primary"
                        to="/requests/add"
                      >
                        Добавить
                      </BButton> -->
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="d-flex mt-1 flex-column
                      flex-fill align-items-end gap-1"
                    >
                      <div class="d-flex w-100 align-items-center">
                        <EntityTableSearch
                          :query.sync="searchQuery"
                        />
                      </div>
                      <div class="d-flex align-items-center gap-1">
                        <div
                          v-if="withHideFinishedFilter && tabsModel !== 1"
                        >
                        <b-form-checkbox
                          v-model="hideFinishedFilter"
                          name="hideFinishedFilter"
                        >
                          Скрыть завершённые
                        </b-form-checkbox>
                      </div>
                      <div
                        v-if="datepicker && mapView"
                        style="color: #7367F0; text-decoration: underline;"
                      >
                        <div class="d-flex gap-2 align-items-end">
                          <div
                            style="cursor: pointer"
                            @click="selectDateForDatepicker('today')"
                          >
                            Сегодня
                          </div>
                          <div
                            style="cursor: pointer"
                            @click="selectDateForDatepicker('tomorrow')"
                          >
                            Завтра
                          </div>
                          <div
                            style="cursor: pointer;display: flex;
                            gap: 12px; align-items: flex-end"
                          >
                            <CalendarRange @update="updateRange" />
                            <div
                              v-if="dateFrom"
                              @click="
                              setDatePreset(undefined, undefined)"
                            >
                              Сбросить фильтр
                            </div>
                            <!-- <Calendar
                              icon="CalendarIcon"
                              only-icon
                              right
                              icon-size="24"
                              :offset="16"
                              style="margin: 0"
                              @updateField="selectDateForDatepicker"
                            /> -->
                          </div>
                        </div>
                      </div>
                      <BButton
                        v-if="creatable
                        && $store.getters['user/permission']('requests:create')
                        && creatableAllowed"
                        style="flex-0"
                        variant="primary"
                        :to="{ path: '/requests/add',
                          query: creatableParams }"
                      >
                        Добавить
                      </BButton>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </b-tabs>
        </BOverlay>
      </BCard>
      <template v-if="gridView && (tabsModel === '1' || tabsModel === 1)">
        <Grid
          :key="lastUpdated"
          :raw="raw"
          :grid-limit.sync="gridLimit"
          :grid-offset.sync="gridOffset"
          :card="RequestCard"
          @needToUpdate="updateGrid"
        />

        <ScrollWatcher @scrolled="scrollGridToEnd" />
      </template>
    </div>
</template>

<script>
import {
  toRefs, ref, watch, computed, provide,
} from '@vue/composition-api';
import {
    BOverlay,
    BTable,
    BBadge,
    BTabs,
    BTab,
    BButton,
    BFormCheckbox, BCard,
} from 'bootstrap-vue';
import {useLocalStorage} from '@vueuse/core';
import SortingConfigure from '@/components/sortingConfigure/sortingConfigure.vue';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import RequestCard from '@/components/requests/RequestCard.vue';
import Deadline from '@/components/Deadline.vue';
import ScrollWatcher from '@/components/scrollWatcher/ScrollWatcher.vue';
import requestTypeColor from '@/utils/requestTypeColor';
import CalendarRange from "@/components/fields/calendarRange/CalendarRange.vue";
import useEntityTable from './useEntityTable';

import EntityTablePagination from './EntityTablePagination.vue';
import EntityTableSearch from './EntityTableSearch.vue';
import Grid from '../Grid.vue';
import EmployeesRequestsMap from '../employeesRequestsMap/EmployeesRequestsMap.vue';

export default {
  components: {
    SortingConfigure,
      BCard,
    BOverlay,
    BTable,
    BBadge,
    EntityTablePagination,
    EntityTableSearch,
    Deadline,
    BTabs,
    BTab,
    ScrollWatcher,
    Grid,
    EmployeesRequestsMap,
    BButton,
    BFormCheckbox,
    CalendarRange,
  },
  props: {
    byFacilityId: {
      type: Number,
      default: null,
    },
    byEmployeeId: {
      type: Number,
      default: null,
    },
    employeeData: Object,
    gridView: Boolean,
    mapView: Boolean,
    selectable: Boolean,
    datepicker: Boolean,
    creatable: Boolean,
    creatableParams: {
      type: Object,
      default: () => {},
    },
    noFacilityColumn: Boolean,
    withHideFinishedFilter: Boolean,
    noSelectView: Boolean,
    noCache: Boolean,
  },
  setup(props) {
    const {
      byFacilityId, byEmployeeId, gridView, noFacilityColumn, noCache,
    } = toRefs(props);
    const {
      buzy,
      action,
      items,
      formatters,
      getList,
      total,
      pagination,
      perPage,
      page,
      totalPages,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      searchQuery,
      viewPageName,
      onRowClicked,
      raw,
      returnRaw,
      lastUpdated,
      cache,
    } = useEntityTable('requestsTable', noCache.value);
    const { t } = useI18n();
    const store = useStore();
    const selectedLocal = ref([]);
    const isMobile = ref(false);
    provide('selectedLocal', selectedLocal);

    isMobile.value = store.state.app.isMobile;

    viewPageName.value = 'requests-view';

    const fieldsArray = [
      {
        key: 'number',
        label: t('requests.field.number.label'),
        sortable: true,
        visible: true,
      },
      {
        key: 'employees',
        label: t('requests.field.employees.label'),
        sortable: true,
        visible: true,
        thClass: 'thEmployees'
      },
      {
        key: 'facility.client.name',
        label: t('requests.field.client.label'),
        sortable: true,
        visible: true,
        thClass: 'thClientName'
      },
      {
        key: 'created_at',
        label: t('requests.field.created_at.label'),
        sortable: true,
        formatter: 'dateFormatter',
        visible: true,
      },
      {
        key: 'name',
        label: t('requests.field.name.label'),
        sortable: true,
        empty: t('requests.field.name.empty'),
        thClass: 'thName',
        visible: true,
      },
      {
        key: 'type',
        label: t('requests.field.type.label'),
        sortable: true,
        empty: 'Не указан',
        visible: true,
      },
      {
        key: 'status',
        label: t('requests.field.status.label'),
        sortable: true,
        empty: 'Не указан',
        visible: true,
      },
      {
        key: 'work_type',
        label: t('requests.field.work_type.label'),
        sortable: true,
        displayParam: 'name',
        visible: true,
      },
      (!noFacilityColumn.value) && {
        key: 'facility',
        label: t('requests.field.facility.label'),
        sortable: true,
        displayParam: 'name',
        visible: true,
      },
      {
        key: 'manager',
        label: t('requests.field.manager.label'),
        sortable: true,
        formatter: 'nameFormatter',
        empty: 'Не назначен',
        visible: true,
      },
      {
        key: 'deadline_at',
        label: t('requests.field.deadline_at.label'),
        sortable: true,
        visible: true,
      },
    ];
    const fields = useLocalStorage('RequestsListColumns', fieldsArray);
    const customTableFields = computed(() => fields.value.filter((i) => i.visible));

    if (byFacilityId.value) {
      action.value = {
        name: 'requests/find',
        payload: { facility_id: byFacilityId.value },
      };
    } else if (byEmployeeId.value) {
      action.value = {
        name: 'requests/find',
        payload: { employee_id: byEmployeeId.value },
      };
    } else {
      action.value = {
        name: 'requests/find',
        payload: {},
      };
    }

    const cacheParams = ref({
      prefix: 'requestsTable',
      storage: !noCache.value,
      url: !noCache.value,
    });
    const tabsModel = cache.create({
      defaultValue: 0,
      name: 'tabsModel',
      number: true,
      ...cacheParams.value,
    });

    const hideFinishedFilter = cache.create({
      defaultValue: false,
      name: 'hideFinishedFilter',
      ...cacheParams.value,
    });

    const types = ref([]);
    const getTypes = async () => {
      types.value = (await store.dispatch('requests/types'))?.data;
    };
    getTypes();
    const getTypeColor = (type) => requestTypeColor(type);

    const statuses = ref([]);
    const getStatuses = async () => {
      statuses.value = (await store.dispatch('requests/statuses'))?.data;
    };
    getStatuses();
    const getStatusColor = (statusId) => {
      const status = statuses.value?.find((i) => statusId === i.id);
      const prefix = 'background-color: ';
      if (status) {
        return `${prefix}rgba(${status.color}, .12);min-width:90px;`;
      } return `${prefix}#ffffff;min-width:90px;`;
    };

    const dateFrom = ref(null);
    const dateTo = ref(null);
    const gridLimit = ref(5);
    const gridOffset = ref(0);
    const tabsModelTriggered = ref(false);
    watch(tabsModel, (value) => {
      gridOffset.value = 0;
      if (value === 1 && gridView.value) {
        action.value.payload.groupBy = 'status_id';
        action.value.payload.limit = gridLimit.value;
        action.value.payload.offset = gridOffset.value;
        returnRaw.value = true;

        store.state.requests.gridDisplayed = true;
      } else {
        action.value.payload.groupBy = undefined;
        action.value.payload.limit = undefined;
        action.value.payload.offset = undefined;
        returnRaw.value = false;

        store.state.requests.gridDisplayed = false;
      }
      action.value.payload.dateFrom = undefined;
      action.value.payload.dateTo = undefined;
      dateFrom.value = null;
      dateTo.value = null;

      action.value.payload.status = ((hideFinishedFilter.value === 'true'
          || hideFinishedFilter.value === true)
          && tabsModel.value !== 1)
          ? [1, 2, 3, 6]
          : undefined;

      if (!tabsModelTriggered.value) {
        tabsModelTriggered.value = true;
        getList();
      } else {
        pagination.value.page = 1;
        getList();
      }
    }, { immediate: true });

    const updateGrid = () => {
      action.value.payload.groupBy = 'status_id';
      action.value.payload.limit = gridLimit.value;
      action.value.payload.offset = gridOffset.value;
      returnRaw.value = true;
      getList();
    };

    const scrollGridToEnd = () => {
      if (buzy.value) return;
      gridOffset.value += gridLimit.value;
      updateGrid();
    };

    const forceUpdateList = ref(null);
    const setDatePreset = (from, to) => {
      dateFrom.value = from;
      dateTo.value = to;
      if (tabsModel.value === 0) {
        getList({
          dateFrom: dateFrom.value,
          dateTo: dateTo.value,
        });
      } else {
        forceUpdateList.value = new Date().toISOString();
      }
    };
    const selectDateForDatepicker = (date) => {
      if (date === 'today') {
        const from = new Date().toISOString().split('T')[0];
        const to = from;

        setDatePreset(from, to);
      } else if (date === 'tomorrow') {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        const from = date.toISOString().split('T')[0];
        const to = from;

        setDatePreset(from, to);
      } else {
        const toFromDate = date.value.split(' ')[0];
        setDatePreset(toFromDate, toFromDate);
      }
    };
    const payload = computed(() => ({
      ...action.value.payload,
      q: searchQuery.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      ...pagination.value,
      dateFrom: dateFrom.value,
      dateTo: dateTo.value,
    }));
    watch(hideFinishedFilter, async (value) => {
      action.value.payload.status = ((value === 'true' || value === true)
          && tabsModel.value !== 1)
          ? [1, 2, 3, 6]
          : undefined;
      getList();
    });

    const { creatable, creatableParams } = toRefs(props);
    const creatableAllowed = computed(() => {
      if (!creatable.value) return false;
      if (creatableParams.value?.facility_id) {
        return store.getters['user/permission']('facilities:update');
      }
      return true;
    });

    const updateRange = (payload) => {
      setDatePreset(payload.dateFrom, payload.dateTo);
    };

    return {
      customTableFields,
      isMobile,
      fields,
      buzy,
      items,
      formatters,
      types,
      statuses,
      total,
      pagination,
      perPage,
      page,
      totalPages,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      searchQuery,
      tabsModel,
      scrollGridToEnd,
      onRowClicked,
      raw,
      gridLimit,
      gridOffset,
      RequestCard,
      updateGrid,
      payload,
      setDatePreset,
      selectDateForDatepicker,
      forceUpdateList,
      lastUpdated,
      hideFinishedFilter,
      getStatusColor,
      getTypeColor,
      creatableAllowed,
      updateRange,
      dateFrom,
    };
  },
};
</script>

<style lang="sass">
@import 'src/@core/scss/base/core/colors/palette-variables.scss'
.requestsTable
  flex: 1
.dark-layout
  .request-type-badge, .request-status-badge
    color: #ffffff
.request-type-badge, .request-status-badge
  color: #82868B
.tabs.noSelectView
  & .nav-item
    display: none
  & .nav-item + .ml-2
    margin-left: 0 !important
.tabs
  .thEmployees
    min-width: 250px
  .thName
    min-width: 400px
  .thClientName
    max-width: 400px
  &.icons
    .nav
      margin: 1.5rem !important
      display: flex
      align-items: center
      & > .nav-item
        width: 39px
        height: 34px
        border: 1px solid var(--primary)
        & + .nav-item
          margin-left: -1px
        &:first-child
          border-top-left-radius: 0.25rem
          border-bottom-left-radius: 0.25rem
        &:nth-last-child(2)
          border-top-right-radius: 0.25rem
          border-bottom-right-radius: 0.25rem
        & + div
          flex: 1
          & .form-control
            height: 38px
        & > .nav-link
          width: 100%
          height: 100%
          display: flex
          align-items: center
          justify-content: center
          &.active
            background-color: transparentize($primary, 0.80)
      & > .nav-item:first-child
        // border-right: 1px solid var(--primary)
</style>
