<template>
  <div
    ref="wrapper"
    class="serviceLog"
  >
    <div class="date-select d-flex align-items-center  px-2 pb-1 justify-content-between">
     <div>
         <CalendarRange
                 opens="right"
                 @update="updateRange"
         />
         <span
                 v-if="filterisActive"
                 @click="clearDateFilter"
         >
        {{ t('facilities.service-log.clear') }}
      </span>
     </div>
        <div>
            <div
                    v-if="userRole.name === 'admin'"
                    v-ripple.400="'rgba(40,199,111, 0.8)'"
                    class="icon-container"
                    @click="exportFunction"
            >
                <feather-icon
                        stroke="#28C76F"
                        icon="DownloadIcon"
                        size="16"
                />
            </div>
        </div>
    </div>
    <BTable
        v-if="data && data.length"
        :fields="fields"
        :items="data"
        responsive
        class="serviceLog-table"
    >
      <template #row-details="row">
        <div
          :style="`max-width: ${detailsWidth}px;`"
          class="serviceLog-details"
        >
          <template v-if="row.item.answer_type === 'number'">
            <apexchart
              style="flex: 1"
              :series="[
                {
                  name: row.item.checklist_name,
                  data: row.item.answersList.map(i => i.value)
                }
              ]"
              height="280"
              type="area"
              :options="{
                labels: row.item.answersList.map(i => i.label),
                dataLabels: {
                  enabled: false
                },
              }"
            />
          </template>
          <template v-if="row.item.answer_type === 'radio'">
            <apexchart
              :series="buildRadioSeries(row.item.answer, row.item.answer_options)"
              height="280"
              type="pie"
              :options="{
                labels: row.item.answer_options,
                dataLabels: {
                  enabled: false
                },
                legend: {
                  show: false,
                },
              }"
            />
            <div style="color: #6E6B7B; margin-top: 12px">
              <div style="margin-bottom: 14px;">
                Варианты
              </div>
              <div
                v-for="(option, index) in row.item.checklist_answer_options"
                :key="option"
                style="display: flex; gap: 48px; margin-bottom: 6px;"
              >
                <div style="flex: 1; font-weight: 500; color: #5E5873;">
                  {{ option }}:
                </div>
                <div style="">
                  {{
                    buildRadioSeries(row.item.answersList, row.item.checklist_answer_options)[index]
                  }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>

      <template #head()="{field}">
        <div>
          <template v-if="field.key === 'checklist_name'">
            <span style="margin-left: 14px">Название</span>
          </template>

          <template v-else>
            {{ field.key.split('_')[1] }}
          </template>
        </div>
      </template>
      <template #cell(checklist_name)="row">
        <div
          v-if="['number', 'radio'].includes(row.item.checklist_answer_type)"
          style="cursor: pointer; display: flex; align-items: center; gap: 12px; margin-left: -14px"
          @click="row.toggleDetails"
        >
          <feather-icon
            icon="BarChart2Icon"
            size="14"
          />
          <div>{{ row.value }}</div>
        </div>
        <div
          v-else
          style="margin-left: 14px"
        >
          {{ row.value }}
        </div>
      </template>

      <template #cell()="{value}">
        <template v-if="value">
          <template
            v-if="
              [
                'text',
                'number',
                'radio',
                'date',
              ].includes(value.answer_type)
            "
          >
            <template v-if="value.answer">
              <template
                v-if="[
                    'date'
                    ].includes(value.answer_type)"
              >
                {{ formatedDate(value.answer) }}
              </template>
              <template
                  v-if="[
                    'radio'
                    ].includes(value.answer_type)"
              >
                <template
                v-if="value.answer_options && value.answer_options.length > 0
                    && value.answer"
                >
                  {{ radioValue(value) }}
                </template>
                <span
                    v-else
                    class="text-muted"
                >
              Пусто
            </span>
              </template>
              <template
                v-if="[
                  'text',
                  'number',
                ].includes(value.answer_type) && String(value.answer).length > 50"
              >
                <feather-icon
                  style="cursor: pointer;"
                  icon="MessageSquareIcon"
                  size="18"
                  @click="openText(value.answer)"
                />
              </template>
              <template
                  v-else-if="[
                  'text',
                  'number',
                ].includes(value.answer_type) && String(value.answer).length <= 50"
              >
                {{ value.answer }}
              </template>
            </template>
            <span
              v-else
              class="text-muted"
            >
              Пусто
            </span>
          </template>

          <template v-else-if="['checkbox'].includes(value.answer_type)">
            <template
v-if="value.answer && value.answer.length > 0
            && value.answer_options && value.answer_options.length > 0"
            >
              {{ checkboxValue(value) }}
            </template>
            <span
              v-else
              class="text-muted"
            >
              Пусто
            </span>
          </template>

          <template v-else-if="value.answer_type === 'switch'">
            <template v-if="value.answer === true || value.answer === false">
              {{ value.answer ? 'Да' : 'Нет' }}
            </template>
            <span
              v-else
              class="text-muted"
            >
              Пусто
            </span>
          </template>

          <template
              v-else-if="['file'].includes(value.answer_type)
              && value.answer && value.answer.length > 0"
          >
            <feather-icon
              style="cursor: pointer;"
              icon="ImageIcon"
              size="18"
              :badge="value.answer.length"
              @click="openImage(value.answer)"
            />
          </template>
          <span
              v-else
              class="text-muted"
          >
            Пусто
          </span>
        </template>

        <span
          v-else
          class="text-muted"
        >
          Пусто
        </span>
      </template>
    </BTable>

    <b-modal
      ref="fileModalRef"
      v-model="fileModal"
      size="lg"
      title="Просмотр файлов"
    >
      <template #modal-footer="{}">
        <BButton @click="$refs['fileModalRef'].hide()">
          Закрыть
        </BButton>
      </template>
      <div class="fileModalImages">
        <b-carousel
            v-model="slide"
            controls
            label-next="''"
            label-prev="''"
            :interval="400000"
            style="width: 100%"
            indicators
        >
          <b-carousel-slide
              v-for="(file, index) in fileModalValue"
              :key="index"
              style="padding-bottom: 40px"
          >
            <template #img>
              <div class="d-flex flex-column align-items-center">
                <img
                    v-if="isImage(file.extension)"
                    :src="file.url"
                    style="width: 60%; max-height: 50vh"
                >
                <video
                    v-if="isVideo(file.extension)"
                    ref="video"
                    :src="file.url"
                    style="width: 60%; max-height: 50vh"
                    controls
                />
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </b-modal>

    <b-modal
      ref="textModalRef"
      v-model="textModal"
      size="lg"
      title="Просмотр текста"
    >
      <template #modal-footer="{}">
        <BButton @click="$refs['textModalRef'].hide()">
          Закрыть
        </BButton>
      </template>
      <div
        class="textModal"
        style="text-align: justify"
      >
        {{ textModalValue }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  computed,
  nextTick,
  onBeforeUnmount, onMounted, ref, toRefs,
} from '@vue/composition-api';
import { BButton, BModal, BTable, BCarousel, BCarouselSlide } from 'bootstrap-vue';
import moment from "moment/moment";
import { useStore } from '@/hooks/useStore';
import fileTypeByExt from '@/utils/fileTypeByExt';
import CalendarRange from "@/components/fields/calendarRange/CalendarRange.vue";
import { useI18n } from "@/hooks/useI18n";
import { formatDate } from "@/utils/time";

export default {
  components: {
    BTable,
    BModal,
    BButton,
    BCarousel,
    BCarouselSlide,
    CalendarRange,
  },
props: {
    facilityId: Number,
    facility: Object
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const { facilityId } = toRefs(props);
    const slide = ref(0);
    const rawData = ref([]);
    const fields = ref([]);
    const data = ref([]);

    const wrapper = ref(null);
    const detailsWidth = ref(0);
     const userRole = computed(() => store.getters['user/role']);
    const { getFileType } = fileTypeByExt();

    const dateFormat = (date) => {
      const formattedDate = date.toISOString().split('T')[0];
      return formattedDate;
    };

    const lastYear = new Date();
    lastYear.setFullYear(lastYear.getFullYear() - 1);

    const dateFrom = ref(dateFormat(lastYear));
    const dateTo = ref(dateFormat(new Date()));
    const resizeDetails = () => {
      detailsWidth.value = wrapper?.value?.clientWidth - 56 || 0;
    };
    onMounted(() => {
      window.addEventListener('resize', resizeDetails);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeDetails);
    });
    moment.locale('ru');
    const createFields = () => {
      // eslint-disable-next-line no-underscore-dangle
      const fields_ = [{
        key: 'checklist_name',
        width: '263px',
      }];
      const formattedData = [];
      for (let i = 0; i < rawData.value.requests.length; i += 1) {
        const element = rawData.value.requests[i];
        fields_.push({
          key: `answers_${moment(element.finished_at).format('L')}_${element.id}`,
          width: '107px',
        });
      }

      for (let i = 0; i < rawData.value.requests.length; i += 1) {
        for (let j = 0; j < rawData.value.requests[i].answers.length; j += 1) {
          const element = rawData.value.requests[i].answers[j];
          formattedData.push({
            [`answers_${moment(rawData.value.requests[i].finished_at).format('L')}_${rawData.value.requests[i].id}`]: element,
          });
        }
      }
      const rowData = [];
      for (let i = 0; i < rawData.value.checklist_items.length; i += 1) {
        const element = rawData.value.checklist_items[i];
        const answer = formattedData.filter(
            (el) => {
              const key = Object.keys(el)[0];
              return el[key].checklist_item_id === element.id;
            }
        );
        const answers = [];
        for (let j = 0; j < answer.length; j += 1) {
          const answerKey = Object.keys(answer)[j];
          const answerDateKey = Object.keys(answer[answerKey])[0];
          const answerOptionsKey = 'answer_options';
          answer[answerKey][answerDateKey][answerOptionsKey] = element.answer_options;
          answers.push({
                [`${answerDateKey}`]: answer[answerKey][answerDateKey]
          });
        }
        const outputObject = answers.reduce((result, currentObject) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(currentObject)) {
            // eslint-disable-next-line no-param-reassign
            result[key] = value;
          }
          return result;
        }, {});

        rowData.push({
          ...outputObject,
          checklist_name: element.name,
        });
      }

      fields.value = fields_;
      data.value = rowData;
    };

    const exportFunction = async () => {
      const response = await store.dispatch('facilities/getExportData', {
        id: facilityId.value,
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // Создание элемента для скачивания
      const link = document.createElement('a');
      link.href = url;
      const fileName = `${props.facility.name}_${dateFrom.value}_${dateTo.value}.xlsx`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click(); // Имитация клика для скачивания
      window.URL.revokeObjectURL(url); // Очистка ресурса
      link.remove(); // Удаление ссылки из документа
    }

    // eslint-disable-next-line no-return-await
    const getServiceLog = async () => {
      const response = await store.dispatch('facilities/getServiceLog', {
        id: facilityId.value,
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
      });
      if (!response.result) return;

      rawData.value = response.data;
      createFields();
      await nextTick();
      resizeDetails();
    };
    getServiceLog();

    const filterisActive = ref(false);
    const updateRange = (payload) => {
      filterisActive.value = true;
      dateFrom.value = payload.dateFrom;
      dateTo.value = payload.dateTo;
      getServiceLog();
    };

    const clearDateFilter = () => {
      filterisActive.value = false;
      dateFrom.value = dateFormat(lastYear);
      dateTo.value = dateFormat(new Date());
      getServiceLog();
    };

    const buildRadioSeries = (answers, options) => {
      const counters = {};
      for (let i = 0; i < options.length; i += 1) {
        const element = options[i];
        counters[element] = 0;
      }

      for (let i = 0; i < answers.length; i += 1) {
        const element = answers[i];
        // eslint-disable-next-line no-prototype-builtins
        if (counters.hasOwnProperty(element.value)) counters[element.value] += 1;
      }

      const series = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in counters) {
        if (Object.hasOwnProperty.call(counters, key)) {
          const element = counters[key];
          series.push(element);
        }
      }

      return series;
    };

    const fileModalValue = ref(null);
    const fileModal = ref(false);
    const openImage = (answer) => {
      fileModal.value = true;
      fileModalValue.value = answer;
    };

    const fileType = (ext) => getFileType(ext);
    const isImage = (ext) => fileType(ext) === 'image';
    const isVideo = (ext) => fileType(ext) === 'video';

    const textModalValue = ref(null);
    const textModal = ref(false);
    const openText = (answer) => {
      textModal.value = true;
      textModalValue.value = answer;
    };

    // eslint-disable-next-line consistent-return
    const formatedDate = (value) => {
      try {
        return formatDate(new Date(value), {
          withYear: true,
          withHours: true,
          withMinutes: true,
        })
      } catch (e) {
         throw Error(`неопознанная дата ${value}`);
      }
    };

    const checkboxValue = (element) => {
      if (!element.answer || element.answer.length === 0) {
        return '';
      }
      const answersArray = [];
     try {
       // eslint-disable-next-line max-len
       const answersArr = Array.isArray(element.answer) ? element.answer : JSON.parse(element.answer);
       answersArr.forEach((el) => {
         // eslint-disable-next-line radix
         answersArray.push(element.answer_options[Number.parseInt(el)]);
       });
     } catch (e) {
        Error('Строка а не массив');
      }

      return answersArray.join(', ');
    };

    const radioValue = (element) =>
        // eslint-disable-next-line radix,implicit-arrow-linebreak
       element.answer_options[parseInt(element.answer)];

    return {
      t,
      fileType,
      isImage,
      clearDateFilter,
      filterisActive,
      isVideo,
      slide,
      updateRange,
      rawData,
      data,
      fields,
      buildRadioSeries,
      wrapper,
      detailsWidth,
      openImage,
      fileModal,
      fileModalValue,
      openText,
      textModalValue,
      textModal,
      formatedDate,
      checkboxValue,
      radioValue,
      userRole,
      exportFunction
    };
  }
};
</script>

<style lang="sass">
.icon-container
    padding: 10px
    border: 1px solid #28C76F
    border-radius: 5px

.serviceLog
  flex: 1
  display: flex
  flex-direction: column
  &-details
    display: flex
  &-table
    flex: 1
    margin-bottom: 0
    & thead
      & tr
        background-color: #F3F2F7
        & th
          border: 2px solid #EBE9F1
        & th:first-child
          width: 0
          white-space: nowrap
    & tbody
      & tr
        & td
          border: 2px solid #EBE9F1
        & td:first-child
          width: 0
          white-space: nowrap
.fileModalImages
  display: flex
  flex-direction: column
  align-items: center
  gap: 12px
  & img
    border-radius: 4px
.date-select
  cursor: pointer
  color: rgb(115, 103, 240)
  text-decoration: underline
.carousel-indicators
  & li
    background-color: rgb(115, 103, 240)
.carousel-control-prev-icon
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237367F0' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important
.carousel-control-next-icon
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237367F0' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important
</style>
