<template>
  <EditInput
    :model="item.type"
    :toggle-only-by-icon="false"
    type="dropdown"
    class="m-0"
    :options="options"
    :readonly="readonly"
    @input="$emit('updateField', { field: 'type_id', value: $event, update: false })"
  >
    <span class="card-text editable-field">
      <template v-if="item.type">
        {{ item.type.name }}
      </template>
      <template v-else-if="item.type_id">
        {{ types.find(i => i.id === item.type_id).name }}
      </template>
      <template v-else>
        {{ t('facilities.field.type.placeholder') }}
      </template>
    </span>
  </EditInput>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import EditInput from '../editInput/EditInput.vue';

export default {
  components: { EditInput },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    readonly: Boolean,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const types = computed(() => store.state.facilities.facilityTypes);
    const options = ref([]);
    (async () => {
      const response = await store.dispatch('facilities/getFacilityTypes');
      if (response.result) {
        store.commit('facilities/setFacilityTypes', response.data);
        options.value = types.value.map((i) => ({ value: i.id, label: i.name }));
      }
    })();
    return {
      t,
      options,
      types,
    };
  },
};
</script>
