<template>
  <BOverlay
    :show="isProcessing"
    opacity="0.8"
    variant="transparent"
  >
    <b-card>
      <div>
        <div v-if="title">
          <h4
            class="mb-1 ml-1 cursor-pointer editable-field"
          >
            Плановая заявка
          </h4>
        </div>
        <ValidationObserver ref="schedulerValid">
          <div class="d-lg-flex">
            <div class="d-md-flex px-0 col-12 col-lg-8 pt-0">
              <div class="col-12 col-md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Объект"
                  rules="required"
                >
                  <b-form-group
                    class="mb-0"
                    label="Объект"
                  >
                    <BFormSelect
                      v-model="scheduler.facility_id"
                      :disabled="!!preSelectFacility ||
                        !$store.getters['user/permission']('schedule:update')"
                      :options="[{text: 'Выберите объект', value: null, disabled: true},
                                 ...facilities]"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-form-group>
                  <small class="text-danger pb-1">{{ errors[0] }}</small>
                </validation-provider>
                <b-form-group
                  class="mt-1"
                  label="Вид работ"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Вид работ"
                    rules="required"
                  >
                    <BFormSelect
                      v-model="scheduler.work_type_id"
                      :options="workTypes"
                      :state="errors.length > 0 ? false:null"
                      :disabled="!$store.getters['user/permission']('schedule:update')"
                    />
                    <small class="text-danger pb-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Ответственный">
                  <validation-provider
                    #default="{ errors }"
                    name="Ответственный"
                    rules="required"
                  >
                    <BFormSelect
                      v-model="scheduler.manager_id"
                      :options="[{text: 'Выберите ответственного', value: null, disabled: true},
                                 ...managers]"
                      :state="errors.length > 0 ? false:null"
                      :disabled="!$store.getters['user/permission']('schedule:update')"
                    />
                    <small class="text-danger pb-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Исполнители">
                  <v-select
                    v-model="scheduler.employees"
                    placeholder="Выберите сотрудника"
                    label="title"
                    :options="employees"
                    :reduce="option => option.value"
                    multiple
                    :disabled="!$store.getters['user/permission']('schedule:update')"
                  />
                </b-form-group>
                <b-form-group
                  label="Бюджет"
                >
                  <cleave
                    v-model="scheduler.price"
                    class="form-control"
                    :options="{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand',
                      delimiter: ' '
                    }"
                    placeholder="Бюджет заявки"
                    :disabled="!$store.getters['user/permission']('schedule:update')"
                  />
                </b-form-group>
              </div>
              <div class="col-12 col-md-6">
                <b-form-group
                  label="Оборудование"
                >
                  <v-select
                    v-model="scheduler.equipments"
                    placeholder="Добавьте оборудование"
                    multiple
                    label="title"
                    :options="equipments"
                    :reduce="option => option.value"
                    :disabled="!$store.getters['user/permission']('schedule:update')"
                  />
                </b-form-group>
                <b-form-group
                  label="Чек-листы"
                >
                  <v-select
                    v-model="scheduler.checklists"
                    placeholder="Добавьте чек-лист"
                    multiple
                    label="title"
                    :options="checklists"
                    :reduce="option => option.value"
                    :disabled="!$store.getters['user/permission']('schedule:update')"
                  />
                </b-form-group>
                <b-form-group
                  label="Начало"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Начало"
                    rules="required"
                  >
                    <b-form-datepicker
                      v-model="scheduler.start_date"
                      selected-variant="primary"
                      nav-button-variant="primary"
                      :hide-header="true"
                      :date-format-options="isMobile ? {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        weekday: 'short'
                      } : {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                        weekday: 'long'
                      }"
                      v-bind="{labelHelp: ''}"
                      placeholder="Выберите дату"
                      local="ru"
                      :start-weekday="1"
                      :state="errors.length > 0 ? false : null"
                      :initial-date="new Date()"
                      :disabled="!$store.getters['user/permission']('schedule:update')"
                    />
                    <small class="text-danger pb-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Конец"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Конец"
                    rules="required"
                  >
                    <b-form-datepicker
                      v-model="scheduler.end_date"
                      :hide-header="true"
                      :date-format-options="isMobile ? {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        weekday: 'short'
                      } : {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                        weekday: 'long'
                      }"
                      v-bind="{labelHelp: ''}"
                      selected-variant="primary"
                      nav-button-variant="primary"
                      placeholder="Выберите дату"
                      local="ru"
                      :start-weekday="1"
                      :state="errors.length > 0 ? false : null"
                      :initial-date="new Date()"
                      :disabled="!$store.getters['user/permission']('schedule:update')"
                    />
                    <small class="text-danger pb-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <BFormCheckbox
                  :checked="!!scheduler.is_active"
                  switch
                  class="mb-1"
                  :disabled="!$store.getters['user/permission']('schedule:update')"
                  @change="updateField('is_active', $event ? 1 : 0)"
                >
                  Активен
                </BFormCheckbox>
                <BFormCheckbox
                  switch
                  :checked="!!scheduler.client_signature"
                  :disabled="!$store.getters['user/permission']('schedule:update')"
                  @change="updateField('client_signature', $event ? 1 : 0)"
                >
                  Подпись клиента
                </BFormCheckbox>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <b-form-group label="Срок выполнения">
                <b-form-select
                  v-model="scheduler.deadline"
                  :options="deadlineTimes"
                  :disabled="!$store.getters['user/permission']('schedule:update')"
                />
              </b-form-group>
                <b-form-group
v-if="scheduler.deadline === 0"
label="Часы"
>
                    <b-form-timepicker
v-model="timeForDay"
:hour24="true"
:show-seconds="false"
/>
                </b-form-group>
              <div
                class="d-flex flex-md-row flex-sm-column"
              >
                <div class="w-100">
                  <b-form-group label="Повтор">
                    <b-form-select
                      v-model="scheduler.repeat"
                      :options="repeatSelect"
                      :disabled="!$store.getters['user/permission']('schedule:update')"
                    />
                  </b-form-group>
                </div>
                <div
                  v-if="scheduler.repeat !== 'week'"
                  class="w-100 ml-0 ml-md-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Каждые"
                    rules="required"
                  >
                    <b-form-group
                      label="Каждые"
                    >
                      <b-input-group
                        :append="scheduler.repeat === 'day'
                          ? 'дн.' : scheduler.repeat === 'month' ? 'мес.' : 'год'"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="scheduler.repeat_every"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          :disabled="!$store.getters['user/permission']('schedule:update')"
                        />
                      </b-input-group>
                    </b-form-group>
                    <small class="text-danger pb-1">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
              <div
                v-if="scheduler.repeat === 'week'"
                class="d-flex justify-content-between"
                style="max-width: 24rem"
              >
                <b-button
                  style="padding: 0.6rem;"
                  size="sm"
                  :variant="!!scheduler.repeat_days.find(item => item === 1)
                    ? 'primary' : 'outline-primary'"
                  :disabled="!$store.getters['user/permission']('schedule:update')"
                  @click="setWeek(1,scheduler.repeat_days.findIndex(item => item === 1))"
                >
                  Пн
                </b-button>
                <b-button
                  style="padding: 0.6rem;"
                  size="sm"
                  :variant="!!scheduler.repeat_days.find(item => item === 2)
                    ? 'primary' : 'outline-primary'"
                  :disabled="!$store.getters['user/permission']('schedule:update')"
                  @click="setWeek(2,scheduler.repeat_days.findIndex(item => item === 2))"
                >
                  Вт
                </b-button>
                <b-button
                  style="padding: 0.6rem;"
                  size="sm"
                  :variant="!!scheduler.repeat_days.find(item => item === 3)
                    ? 'primary' : 'outline-primary'"
                  :disabled="!$store.getters['user/permission']('schedule:update')"
                  @click="setWeek(3,scheduler.repeat_days.findIndex(item => item === 3))"
                >
                  Ср
                </b-button>
                <b-button
                  style="padding: 0.6rem;"
                  size="sm"
                  :variant="!!scheduler.repeat_days.find(item => item === 4)
                    ? 'primary' : 'outline-primary'"
                  :disabled="!$store.getters['user/permission']('schedule:update')"
                  @click="setWeek(4,scheduler.repeat_days.findIndex(item => item === 4))"
                >
                  Чт
                </b-button>
                <b-button
                  style="padding: 0.6rem;"
                  size="sm"
                  :variant="!!scheduler.repeat_days.find(item => item === 5)
                    ? 'primary' : 'outline-primary'"
                  :disabled="!$store.getters['user/permission']('schedule:update')"
                  @click="setWeek(5,scheduler.repeat_days.findIndex(item => item === 5))"
                >
                  Пт
                </b-button>
                <b-button
                  style="padding: 0.6rem;"
                  size="sm"
                  :variant="!!scheduler.repeat_days.find(item => item === 6)
                    ? 'primary' : 'outline-primary'"
                  :disabled="!$store.getters['user/permission']('schedule:update')"
                  @click="setWeek(6, scheduler.repeat_days.findIndex(item => item === 6))"
                >
                  Сб
                </b-button>
                <b-button
                  style="padding: 0.6rem;"
                  size="sm"
                  :variant="!!scheduler.repeat_days.find(item => item === 7)
                    ? 'primary' : 'outline-primary'"
                  :disabled="!$store.getters['user/permission']('schedule:update')"
                  @click="setWeek(7, scheduler.repeat_days.findIndex(item => item === 7))"
                >
                  Вс
                </b-button>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-end mt-2"
          >
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="cancel"
            >
              Отменить
            </b-button>
            <BOverlay
              :show="isLoading"
              opacity="0.8"
              variant="transparent"
            >
              <b-button
                v-if="$store.getters['user/permission']('schedule:update')"
                variant="primary"
                @click="setSchedulerData"
              >
                Сохранить
              </b-button>
            </BOverlay>
          </div>
        </ValidationObserver>
      </div>
    </b-card>
  </BOverlay>
</template>

<script>

import {
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BOverlay,
  BCard,
  BFormDatepicker,
  BFormTimepicker,
  BButton,
  BFormSelect,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import vSelect from 'vue-select';
import { ref, computed, toRefs } from '@vue/composition-api';
import Cleave from 'vue-cleave-component';
import { useStore } from '@/hooks/useStore';
import {
  createScheduler, getScheduler, updateScheduler,
} from '@/store/scheduler/scheduler.api';
import { useRouter } from '@/hooks/useRouter';
import { useToast } from '@/hooks/useToast';
import { DateTime } from "luxon";

export default {
  name: 'SchedulerAddPage',
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BOverlay,
    BCard,
    BFormDatepicker,
    BButton,
    BFormSelect,
    vSelect,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BFormTimepicker

  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    preSelectFacility: Number,
    title: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const { preSelectFacility } = toRefs(props);
    const scheduler = ref({
      name: '',
      work_type_id: 1,
      is_active: 0,
      start_date: null,
      end_date: null,
      facility_id: preSelectFacility.value || null,
      equipments: [],
      checklists: [],
      repeat: 'year',
      repeat_every: 1,
      repeat_days: [],
      deadline: 3,
      client_signature: 0,
      price: '',
      employees: [],
      manager_id: null,
    });

    const store = useStore();
    const isMobile = ref(false);
    isMobile.value = store.state.app.isMobile;

    if (!store.state.checklists.length)store.dispatch('getChecklists');
    if (!store.state.equipments.length)store.dispatch('getEquipments');
    if (!store.state.managers.length)store.dispatch('getManagers');
    if (!store.state.facilities.length)store.dispatch('getFacilities');
    if (!store.state.workTypes.length)store.dispatch('getWorkTypes');
    if (!store.state.employees.length)store.dispatch('getEmployees');

    const repeatSelect = ref([
      { text: 'По годам', value: 'year' },
      { text: 'По месяцам', value: 'month' },
      { text: 'По неделям', value: 'week' },
      { text: 'По дням', value: 'day' },
    ]);

    const deadlineTimes = ref([
      { text: 'Срок в часах-минутах', value: 0 },
      { text: 'В течение дня', value: 1 },
      { text: '2 дня', value: 2 },
      { text: '3 дня', value: 3 },
      { text: 'Неделя', value: 7 },
    ]);

    const isProcessing = ref(false);
    const { router } = useRouter();
    const { successToast, dangerToast } = useToast();

    const workTypes = computed(() => store.getters.allWorkTypes);
    const facilities = computed(() => store.getters.allFacilities);
    const equipments = computed(() => store.getters.allEquipments);
    const employees = computed(() => store.getters.allEmployees);
    const managers = computed(() => store.getters.allManagers);
    const checklists = computed(() => store.getters.allChecklists);

    const schedulerValid = ref(null);

    const getSchedulerById = () => {
      isProcessing.value = true;
      getScheduler(props.id).then((res) => {
        scheduler.value = {...scheduler.value, ...res.data.response};
        scheduler.value.equipments = scheduler.value.equipments?.map((item) => item.id);
        scheduler.value.checklists = scheduler.value.checklists?.map((item) => item.id);
        scheduler.value.employees = scheduler.value.employees?.map((item) => item.id);
      }).finally(() => { isProcessing.value = false; });
    };

    if (props.id) {
      getSchedulerById();
    }

    const updateField = (field, value) => {
      scheduler.value[field] = value;
    };

    const setWeek = (weekNum, index) => {
      if (index !== -1) {
        scheduler.value.repeat_days.splice(index, 1);
        return;
      }
      scheduler.value.repeat_days.push(weekNum);
      scheduler.value.repeat_days.sort();
    };
    const isLoading = ref(false);

    const setSchedulerData = () => {
      schedulerValid.value.validate().then((success) => {
        if (!success) return;
        isLoading.value = true;
        if (props.id) {
          updateScheduler(props.id, scheduler.value)
            .then(() => {
              if (!preSelectFacility.value) router.push({ name: 'scheduler' });
              else emit('input');
              successToast('Успешно обновлено');
            })
            .catch(() => {
              dangerToast('Ошибка');
            })
            .finally(() => {
              isLoading.value = false;
            });
          return;
        }
        createScheduler(scheduler.value)
          .then(() => {
            successToast('Успешно добавлено');
            if (!preSelectFacility.value) router.push({ name: 'scheduler' });
            else emit('input');
          })
          .catch(() => {
            dangerToast('Ошибка');
          })
          .finally(() => {
            isLoading.value = false;
          });
      });
    };

    const cancel = () => {
      if (!preSelectFacility.value) router.push({ name: 'scheduler' });
      else emit('close');
    };
    const timeForDay = computed({
      get() {
        // Используем Luxon для получения даты
        const data = scheduler.value.start_date !== null
            ? DateTime.fromISO(scheduler.value.start_date).toUTC()
            : DateTime.now().toUTC();
        // Возвращаем время в формате 'HH:mm:ss'
        return data.toFormat('HH:mm:ss');
      },
      set(newTime) {
        let data = scheduler.value.start_date !== null
            ? DateTime.fromISO(scheduler.value.start_date).toUTC()
            : DateTime.now().toUTC();

        // Разбиваем новое время на часы, минуты, секунды и обновляем дату
        const [hours, minutes, seconds] = newTime.split(':').map(Number);
        data = data.set({ hour: hours, minute: minutes, second: seconds });
        // Обновляем начальную дату в scheduler.value, преобразуя дату обратно в строку ISO
        scheduler.value.start_date = `${data.toUTC().toISO().slice(0, -1)}000Z`;
      },
    });

    return {
      schedulerValid,
      isLoading,
      setWeek,
      employees,
      managers,
      setSchedulerData,
      workTypes,
      scheduler,
      updateField,
      isProcessing,
      repeatSelect,
      facilities,
      equipments,
      checklists,
      deadlineTimes,
      required,
      cancel,
      isMobile,
      timeForDay
    };
  },

};
</script>
