<template>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center mr-2">
      <feather-icon
        icon="UserIcon"
        class="mr-75"
      />
      <span class="font-weight-bold">{{ t('facilities.field.client.label') }}</span>
    </div>
    <EditInput
      :model="selectedClientName"
      :with-select="true"
      :toggle-only-by-icon="false"
      :hide-edit-icon="true"
      :only-exists="true"
      :options="options"
      :readonly="readonly"
      @input="onInput"
    >
      <span
          :class="'mb-0 cursor-pointer editable-field '
              + (isError ? 'invalid' : '')"
      >
        {{ selectedClientName || t('facilities.field.client.placeholder') }}
      </span>
    </EditInput>
  </div>
</template>

<script>
import {computed, ref, toRefs} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import EditInput from '../editInput/EditInput.vue';

export default {
  components: { EditInput },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isError: {
      type: Boolean,
      default: false
    },
    isNew: Boolean,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const { item, isNew } = toRefs(props);
    const store = useStore();
    const { t } = useI18n();

    const clients = ref([]);
    (async () => {
      const { result, data } = await store.dispatch('clients/getClientsForSelector');
      if (result) {
        clients.value = data.map((i) => ({
          label: i.name,
          value: i.id,
        }));
      }
    })();
    const options = computed(() => {
      if (item.value.client_id) {
        return [{label: t('facilities.clear-client'), value: null}].concat(clients.value.filter((employee) => employee.id !== item.value.client_id));
      }
      return [{label: t('facilities.clear-client'), value: null}].concat(clients.value);
    });

    const localValue = ref(null);
    const selectedClientName = computed(() => {
      if (!isNew.value) return item.value.client_id ? item.value.client?.name : null;
      return localValue.value;
    });

    const onInput = (value) => {
      if (isNew.value) {
        const { label } = options.value.find((i) => i.value === value);
        localValue.value = label;
      }

      emit('updateField', { field: 'client_id', value, update: true });
    };

    return {
      t,
      options,
      selectedClientName,
      onInput,
    };
  },
};
</script>
