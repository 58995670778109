<template>
  <div class="employees-requests-map">
    <b-row>
      <template
      v-if="!isMobile"
>
        <b-col
            cols="12"
            md="4"
            class="employees-requests-map_cards"
        >
          <div
              v-if="employees.length > 0"
              class="border-top"
          >
            <div
                v-for="employeeObject in employeesArray"
                :key="`${employeeObject.id}`"
                class="px-2 py-1 border-bottom d-flex align-items-top gap-2"
            >
              <div
                  v-if="selectable"
                  class="mt-50"
              >
                <b-form-checkbox
                    :id="`selected-${employeeObject.id}`"
                    v-model="selectedLocal"
                    :value="employeeObject.id"
                    name="selected"
                />
              </div>
              <employee-small-card
                  :employee="employeeObject"
                  with-requests
                  with-workload
                  :requests-opened="employeeObject.id === openedEmployee"
                  @requestsOpened="openRequestByEmployee"
                  @requestsClosed="closeRequest"
                  @requestsLoaded="requestsLoaded"
              />
            </div>
          </div>
          <template v-else-if="employee">
            <employee-requests-cards
                :key="forceUpdateList"
                :payload="payload"
                :employee-id="employee.id"
                @requestsLoaded="requestsLoaded"
                @clicked="moveToRequest"
            />
          </template>
        </b-col>
        <b-col
            cols="12"
            md="8"
            class="pl-xl-0 employees-requests-map_map"
        >
          <requests-map
              :requests="requests"
              :employees="employeesList"
              :center-on-request="centerOnRequest"
              :current-request="currentRequest"
              :opened-ticket="openedRequestId"
              :opened-employee-model="openedEmployeeModel"
              @mapChange="$emit('mapChange', $event)"
          />
        </b-col>
      </template>
      <template
        v-else
      >
        <b-col
            cols="12"
            md="8"
            class="pl-xl-0 employees-requests-map_map"
        >
          <requests-map
              :requests="requests"
              :employees="employeesList"
              :center-on-request="centerOnRequest"
              :current-request="currentRequest"
              :opened-ticket="openedRequestId"
              :opened-employee-model="openedEmployeeModel"
              @mapChange="$emit('mapChange', $event)"
          />
        </b-col>
        <b-col
            cols="12"
            md="4"
            class="employees-requests-map_cards"
        >
          <div
              v-if="employees.length > 0"
              class="border-top"
          >
            <div
                v-for="employeeObject in employeesArray"
                :key="`${employeeObject.id}`"
                class="px-2 py-1 border-bottom d-flex align-items-top gap-2"
            >
              <div
                  v-if="selectable"
                  class="mt-50"
              >
                <b-form-checkbox
                    :id="`selected-${employeeObject.id}`"
                    v-model="selectedLocal"
                    :value="employeeObject.id"
                    name="selected"
                />
              </div>
              <employee-small-card
                  :employee="employeeObject"
                  with-requests
                  with-workload
                  :requests-opened="employeeObject.id === openedEmployee"
                  @requestsOpened="openRequestByEmployee"
                  @requestsClosed="closeRequest"
                  @requestsLoaded="requestsLoaded"
              />
            </div>
          </div>
          <template v-else-if="employee">
            <employee-requests-cards
                :key="forceUpdateList"
                :payload="payload"
                :employee-id="employee.id"
                @requestsLoaded="requestsLoaded"
                @clicked="moveToRequest"
            />
          </template>
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
import {
  ref, toRefs, watch, inject,
} from '@vue/composition-api';
import { BRow, BCol, BFormCheckbox } from 'bootstrap-vue';
import {useStore} from "@/hooks/useStore";
import EmployeeSmallCard from '../employeeSmallCard/EmployeeSmallCard.vue';
import RequestsMap from '../requestsMap/RequestsMap.vue';
import EmployeeRequestsCards from '../employeeRequestsCards/EmployeeRequestsCards.vue';

export default {
  name: 'EmployeesRequestsMap',
  components: {
    RequestsMap,
    EmployeeSmallCard,
    BRow,
    BCol,
    EmployeeRequestsCards,
    BFormCheckbox,
  },
  props: {
    selectable: Boolean,
    employee: {
      type: Object,
      default: () => {},
    },
    employees: {
      type: Array,
      default: () => [],
    },
    lastUpdateState: {
      type: String,
      default: null,
    },
    currentRequest: {
      type: Object,
      default: () => {}
    },
    centerOnRequest: {
      type: Boolean,
      default: false
    },
    payload: Object,
    forceUpdateList: String,
  },
  setup(props) {
    const selectedLocal = inject('selectedLocal');
    const { employee, employees, lastUpdateState } = toRefs(props);

    const requests = ref([]);
    const openedEmployee = ref(null);
    const openedEmployeeModel = ref(null);
    const employeesList = ref([]);
    const isMobile = ref(false);
    const store = useStore();

    isMobile.value = store.state.app.isMobile;
    // const servicemans = ref([]);

    // const getServiceMans = async () => {
    //   servicemans.value = (await store.dispatch('getServiceMans'))?.data;
    // };
    // getServiceMans();
    employeesList.value = employee.value !== undefined
        ? [employee.value]
        : employees.value;
    const closeRequest = () => {
      openedEmployee.value = null;
        openedEmployeeModel.value = null;
      requests.value = [];
      employeesList.value = employee.value !== undefined
          ? [employee.value]
          : employees.value;
    };
    const openRequestByEmployee = (employeeId) => {
      closeRequest();
      openedEmployee.value = null;
      openedEmployee.value = employeeId;
        // eslint-disable-next-line max-len
      openedEmployeeModel.value = employeesList.value.filter((el) => el.id === openedEmployee.value);
    };
    const openedRequestId = ref(null);
    const openRequestById = (requestId) => {
      openedRequestId.value = requestId;
    };
    const requestsLoaded = (reqs) => {
      employeesList.value = employee.value !== undefined
          ? [employee.value]
          : employeesList.value.filter((el) => el.id === openedEmployee.value);
      requests.value = reqs;
    };

    const employeesArray = ref([]);
    const updateList = () => {
      if (employee.value !== undefined) {
        employeesArray.value = [employee.value];
        // employee.value = { ...employee.value };
      } else if (employees.value) {
        employeesArray.value = employees.value;
      }
    };

    watch(
      props,
      (value, oldValue) => {
        // if (value.forceUpdateList) {

        // }
        if (value.employee) {
          if (value.id !== oldValue) employee.value = value.employee;
        } else if (value.employees) {
          employees.value = value.employees;
          employeesList.value = employee.value !== undefined
              ? [employee.value]
              : employees.value;
          if (lastUpdateState.value === 'search' || !lastUpdateState.value) {
            closeRequest();
            if (employees.value[0]?.id) openRequestByEmployee(employees.value[0].id);
          }
        }
        updateList();
      },
      { immediate: true },
    );

    const moveToRequest = (id) => {
      openRequestById(id);
    };

    return {
      selectedLocal,
      employeesArray,
      employeesList,

      openRequestByEmployee,
      closeRequest,
      openedEmployee,
      requestsLoaded,
      requests,

      moveToRequest,
      openedRequestId,

      isMobile,

        openedEmployeeModel,
    };
  },
};
</script>

<style lang="sass">
.employees-requests-map
  &_cards
    @media (max-width: 767px)
      max-height: 320px
      overflow-y: auto
      padding-top: 12px
      padding-bottom: 12px
      margin-bottom: 16px
      border-radius: 8px
      border: 1px solid #eee
</style>
