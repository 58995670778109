<template>
  <div class="calendarRange">
    <DateRangePicker
      v-model="dateRange"
      :locale-data="localeData"
      :single-date-picker="'range'"
      :start-weekday="1"
      :opens="opens"
      :ranges="rangesOptions"
      @update="onUpdate"
    >
      <template
        #input="{}"
        style="min-width: 350px;"
      >
        <feather-icon
          v-if="icon"
          :icon="icon"
          :size="iconSize"
        />
        <slot v-else />
      </template>
    </DateRangePicker>
  </div>
</template>

<script>
import { computed, ref, toRefs } from '@vue/composition-api';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  components: {
    DateRangePicker,
  },
  props: {
    icon: {
      type: String,
      default: 'CalendarIcon',
    },
    iconSize: {
      type: String,
      default: '24',
    },
    value: {
      type: Object,
      default: () => ({
        dateFrom: '',
        dateTo: '',
      }),
    },
    ranges: {
      type: Object,
      default: () => null,
    },
    opens: {
      type: String,
      default: 'left'
    }
  },
  setup(props, { emit }) {
    const dateRange = ref({
      startDate: new Date(),
      endDate: new Date(),
    });

    const localeData = {
      direction: 'ltr',
      format: 'dd/mm/yyyy',
      separator: ' - ',
      applyLabel: 'Ок',
      cancelLabel: 'Отмена',
      weekLabel: 'Неделя',
      customRangeLabel: 'Выбрать',
      daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      firstDay: 1,
    };

    const createRanges = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      return {
        // Сегодня: [today, today],
        // Вчера: [yesterday, yesterday],
        'Прошлый месяц': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'Этот месяц': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
        'Этот год': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
      };
    };

    const { ranges } = toRefs(props);
    const rangesOptions = computed(() => ranges.value || createRanges());

    const dateDoubleDigit = (payload) => (`0${payload}`).slice(-2);
    const formatDate = (payload) => {
      const year = payload.getFullYear();
      const month = dateDoubleDigit(payload.getMonth() + 1);
      const day = dateDoubleDigit(payload.getDate());
      return `${year}-${month}-${day}`;
    };

    const onUpdate = (payload) => {
      const dateFrom = formatDate(payload.startDate);
      const dateTo = formatDate(payload.endDate);
      let name = '';

      // eslint-disable-next-line no-restricted-syntax
      for (const key in rangesOptions.value) {
        if (Object.hasOwnProperty.call(rangesOptions.value, key)) {
          const item = rangesOptions.value[key];

          const itemDateFrom = formatDate(item[0]);
          const itemDateTo = formatDate(item[1]);

          if (dateFrom === itemDateFrom && dateTo === itemDateTo) {
            name = key;
            break;
          } else {
            name = `${dateFrom} - ${dateTo}`;
          }
        }
      }

      emit('update', { dateFrom, dateTo, name });
    };

    return {
      dateRange,
      localeData,
      createRanges,
      formatDate,
      onUpdate,
      rangesOptions,
    };
  },
};
</script>

<style lang="sass">
.calendarRange
  position: relative
  display: flex
  & .ranges .active
    background-color: #7367f0 !important
  & td.active
    background-color: #7367f0
  & td.in-range:not(.active)
    color: #7367f0
    background-color: rgb(115 103 240 / 15%)
  & .daterangepicker
    min-width: unset !important
    color: #7367f0 !important
  & .next, & .prev
    span
      border-color: #7367f0 !important
  & .vue-daterange-picker
    min-width: unset !important
    & .reportrange-text
      background-color: inherit !important
    & .form-control
      border: none
      padding: 0
      height: auto !important
      width: auto !important
      display: flex
      color: inherit
      align-items: flex-end
      & svg
        margin: 0 !important
    & button
      border: 1px solid #7367f0 !important
      font-weight: 500 !important
      &.cancelBtn
        background-color: transparent !important
        color: #7367f0 !important
      &.applyBtn
        background-color: #7367f0 !important
.dark-layout
  .calendarRange
    & .daterangepicker, & .calendar-table, & th, & td
      background-color: #283046
    & td.active
      background-color: #161D31
    & td.in-range:not(.active)
      color: #fff
      background-color: rgb(22 29 49 / 45%)
    & .next, & .prev
      span
        border-color: #fff
</style>
