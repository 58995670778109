<template>
  <b-overlay
    class="avatar-upload"
    :show="isDeleting"
    variant="transparent"
  >
    <b-media no-body>
      <BOverlay
        v-if="avatarUrl && !isFull"
        :show="true"
        variant="dark"
      >
        <template #overlay>
          <div
            class="avatar-upload__avatar-overlay"
            :style="{
              width: avatarSize,
              height: avatarSize,
            }"
          >
            <feather-icon
              size="21"
              icon="UploadIcon"
              class="text-success"
              @click="selectFile"
            />
            <feather-icon
              size="21"
              class="text-danger"
              icon="TrashIcon"
              @click="onDelete"
            />
          </div>
        </template>
        <b-avatar
          class="badge-light-success"
          :variant="variant"
          :src="avatarUrl"
          :size="avatarSize"
          rounded
          :button="true"
          @click="selectFile"
        />
      </BOverlay>
      <b-avatar
        v-else
        class="badge-light-success 234"
        :variant="variant"
        :src="avatarUrl"
        :size="avatarSize"
        rounded
        :button="true"
        @click="selectFile"
      />

      <div
        v-if="isFull && !disabled"
        class="mt-75 ml-75 pl-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="selectFile"
        >
          {{ t('settings.avatar.upload') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-primary"
          size="sm"
          class="mb-75 mr-75"
          @click="onDelete"
        >
          {{ t('settings.avatar.delete') }}
        </b-button>
        <br>
        <b-card-text>
          {{ t('settings.avatar.description') }}
        </b-card-text>
      </div>
    </b-media>

    <b-modal
      ref="cropperModal"
      class="cropper-modal"
      body-class="p-0"
      centered
      hide-footer
      no-close-on-backdrop
      header-class="zindex-4"
      @hidden="clearImage"
    >
      <cropper
        ref="cropper"
        class="upload-example-cropper"
        stencil-component="circle-stencil"
        :src="selectedImage.src"
      />
      <b-button
        v-if="selectedImage.src"
        variant="flat-success"
        block
        @click="onSubmit"
      >
        {{ t('settings.save') }}
      </b-button>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCardText,
  BMedia,
  BModal,
  BOverlay,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { ref, toRefs } from '@vue/composition-api';
import { useToast } from '@/hooks/useToast';
import { useI18n } from '@/hooks/useI18n';

const MAX_AVATAR_SIZE = 1024 * 800;

export default {
  components: {
    BMedia,
    BAvatar,
    BButton,
    BModal,
    BCardText,
    BOverlay,

    Cropper,
  },
  directives: {
    Ripple,
  },
  props: {
    avatarUrl: String,
    config: {
      type: Object,
      required: true,
    },
    isFull: Boolean,
    avatarSize: {
      type: String,
      default: '90px',
    },
    variant: {
      type: String,
      default: 'secondary',
    },
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const { config, disabled } = toRefs(props);

    const { t } = useI18n();
    const { dangerToast } = useToast();

    const {
      updateHandler,
      deleteHandler,
    } = config.value;

    const cropper = ref(null);
    const cropperModal = ref(null);
    const selectedImage = ref({
      file: null,
      src: null,
    });

    const selectFile = () => {
      if (disabled.value) return;
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = ['image/png', 'image/jpeg', 'image/gif'].join(',');

      input.addEventListener('change', (e) => {
        const file = e.target.files[0];
        if (!file) return;
        if (file.size <= MAX_AVATAR_SIZE) {
          selectedImage.value = {
            file,
            src: URL.createObjectURL(file),
          };
          cropperModal.value.toggle();
          return;
        }
        dangerToast(
          t('settings.avatar.oversizeMessage.title'),
          t('settings.avatar.oversizeMessage.text'),
        );
      });

      input.addEventListener('error', () => {
        input.remove();
      });

      input.click();
    };

    const getCropperResult = (cropper, imageType) => new Promise((resolve) => {
      const { canvas } = cropper.getResult();
      canvas.toBlob((avatar) => resolve(avatar), imageType);
    });

    const clearImage = () => {
      selectedImage.value = {
        file: null,
        src: null,
      };
    };

    const isUpdating = ref(false);
    const isDeleting = ref(false);

    const onSubmit = async () => {
      if (isUpdating.value || isDeleting.value) return;

      const avatar = await getCropperResult(cropper.value, selectedImage.value.file.type);
      const formData = new FormData();
      const filename = selectedImage.value.file.name;
      formData.append('avatar', avatar, filename);

      isUpdating.value = true;
      const { result } = await updateHandler(formData);
      isUpdating.value = false;

      cropperModal.value.toggle();
      clearImage();

      if (result) {
        // successToast(
        //   t('settings.avatar.profile.uploaded.title'),
        //   t('settings.avatar.profile.uploaded.text'),
        // );
        emit('updated');
        return;
      }

      dangerToast(
        t('settings.avatar.errorMessage.title'),
        t('settings.avatar.errorMessage.text'),
      );
    };

    const onDelete = async () => {
      if (isUpdating.value || isDeleting.value) return;
      isDeleting.value = true;
      const { result } = await deleteHandler();
      isDeleting.value = false;

      if (result) {
        emit('updated');
      }
    };

    return {
      t,
      cropper,
      cropperModal,

      selectFile,
      selectedImage,

      isUpdating,
      isDeleting,
      onSubmit,

      onDelete,

      clearImage,
    };
  },
};
</script>

<style lang="scss">
  .avatar-upload {
    &:hover {
      .b-overlay {
        opacity: 1;
      }
    }
    .b-overlay {
      opacity: 0;
      border-radius: 6px;
      transition: .2s opacity;
      overflow: hidden;
      cursor: pointer;
    }
    &__avatar-overlay {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 8px;
      & svg {
        flex: 1;
      }
    }
  }
</style>
