<template>
    <div class="grid">
        <div
                v-if="statuses.length"
                class="d-flex grid-wrapper gap-2"
        >
            <template v-for="(group, index) in groups">
                <div
                        v-if="statuses.map(i => i.id).includes(+index)"
                        :key="index"
                        class="grid-wrapper-group"
                >
                    <h4
                            class="mb-2"
                            :style="getStatusColor(index)"
                    >
                        {{ getStatus(index) && getStatus(index).name }}
                    </h4>

                    <draggable
                            :id="index"
                            class="draggable-group"
                            :list="group"
                            group="cards"
                            :force-fallback="true"
                            @add="onDrag"
                    >
                        <component
                                :is="card"
                                v-for="item in group"
                                :key="item.id"
                                :item="item"
                                class="draggable-group-card"
                        />

                        <!-- <component
                        :is="card"
                        :key="item.id"
                        :item="item"
                      /> -->
                    </draggable>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import {
  toRefs, ref, watch,
} from '@vue/composition-api';
import draggable from 'vuedraggable';
import { useI18n } from '@/hooks/useI18n';
import { useToast } from '@/hooks/useToast';
import { useStore } from '@/hooks/useStore';

export default {
  components: {
    BCard,
    draggable,
  },
  props: {
    raw: Object,
    gridLimit: Number,
    gridOffset: Number,
    card: Object,
  },
  setup(props, { emit }) {
    const store = useStore();

    const buzy = ref(false);
    const { raw } = toRefs(props);

    const emptyGroups = ref([]);
    const groups = ref({});

    const force = ref(false);
    const first = ref(true);
    watch(raw, (value) => {
      if (first.value) {
        groups.value = { ...emptyGroups.value, ...value?.data?.items };
        first.value = false;
      } else {
        const items = value?.data?.items;
        const newGroups = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const key in groups.value) {
          if (Object.hasOwnProperty.call(groups.value, key)) {
            const group = groups.value[key];
            newGroups[key] = [...group, ...items[key] || []];
          }
        }

        groups.value = { ...newGroups };
      }
    });

    const createGroups = (statuses) => {
      for (let i = 0; i < statuses.length; i += 1) {
        const status = statuses[i];
        groups.value[status.id] = [];
      }
      emptyGroups.value = { ...groups.value };
    };
    const statuses = ref([]);
    const getStatuses = async () => {
      statuses.value = (await store.dispatch('requests/statuses'))?.data;

      createGroups(statuses.value);
    };
    const getStatus = (id) => {
      const status = statuses.value.find((i) => i.id === +id);
      return status;
    };
    getStatuses();
    const getStatusColor = (statusId) => {
      const status = statuses.value?.find((i) => +statusId === i.id);
      const prefix = 'color: ';
      if (status) {
        return `${prefix}rgb(${status.color});`;
      } return undefined;
    };

    const { t } = useI18n();

    const { successToast, dangerToast } = useToast();
    const updateStatus = async ({ id, status, update = false }) => {
      const { result } = await store.dispatch('requests/update', {
        id,
        model: {
          status_id: status,
        },
      });

      if (result) {
        successToast(t('settings.messageUpdated.title'), t('settings.messageUpdated.text'));
        if (update) {
          force.value = true;
          emit('needToUpdate');
        }
      } else {
        dangerToast(t('errors.something-went-wrong.text'), t('errors.message.updateFailedMessage'));
      }
    };

    return {
      groups,
      t,
      buzy,
      updateStatus,
      force,
      statuses,
      getStatusColor,
      getStatus,
    };
  },
  methods: {
    onDrag(e) {
      // const fromStatus = e.from.id;
      const toStatus = e.to.id;
      // eslint-disable-next-line no-underscore-dangle
      const item = e.item._underlying_vm_;
      this.buzy = true;
      this.updateStatus({ id: item.id, status: toStatus, update: false });
    },
  },
};
</script>

<style lang="sass">
.grid
  overflow-x: auto
  background-color: unset !important
  &-wrapper
    align-items: stretch
    &-group
      min-width: 348px
      display: flex
      flex-direction: column
      & .draggable-group
        flex: 1
  & .draggable
    &-group
      &-card
        display: block

</style>
