<template>
  <BOverlay :show="buzy">
    <BCard
      no-body
      class="facility-tables-card"
    >
      <BTabs>
        <BTab lazy>
          <template #title>
            {{ t('pageTitle.requests') }}
          </template>

          <RequestsTable
            :by-facility-id="item.id"
            creatable
            :creatable-params="{ facility_id: item.id }"
            no-facility-column
            with-hide-finished-filter
            no-select-view
            no-cache
          />
        </BTab>
        <BTab lazy>
          <template #title>
            {{ t('pageTitle.equipment') }}
          </template>

          <EquipmentsTable
            :by-facility-id="item.id"
            linking-button
            :linked-to-facility="item.equipment_ids"
            no-export
            no-tabs
            no-cache
          />
        </BTab>
        <BTab lazy>
          <template #title>
            {{ t('pageTitle.scheduler') }}
          </template>

          <Scheduler :by-facility-id="item.id" />
        </BTab>
        <BTab
          lazy
        >
          <template #title>
            {{ t('pageTitle.mainterance') }}
          </template>

          <ServiceLog
            :facility-id="item.id"
            :facility="item"
          />
        </BTab>
      </BTabs>
    </BCard>
  </BOverlay>
</template>

<script>
import {
  BCard, BOverlay, BTabs, BTab,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import RequestsTable from '../entityTables/RequestsTable.vue';
import EquipmentsTable from '../entityTables/EquipmentsTable.vue';
import Scheduler from './Scheduler.vue';
import ServiceLog from './ServiceLog.vue';

export default {
  components: {
    BCard,
    BOverlay,
    BTabs,
    BTab,
    RequestsTable,
    EquipmentsTable,
    Scheduler,
    ServiceLog,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n();

    const buzy = ref(false);

    return {
      t,
      buzy,
    };
  },
};
</script>

<style lang="sass">
.nav.nav-tabs
  @media (max-width: 600px)
    display: block
.facility-tables-card
  min-height: 800px
  .tabs, .b-overlay-wrap, .tab-pane, .card.grid
    display: flex
    flex-direction: column
    flex: 1
    > .tab-content
      display: flex
      flex-direction: column
      flex: 1
  & .nav-tabs
    margin-left: 24px
    margin-right: 24px
    margin-top: 16px
    & .nav-item
      & a
        font-size: 16px
        @media (max-width: 600px)
          font-size: 14px
          padding: 0.61rem 4px
  & .equipments-table
    & td
      max-width: 281px
      & .text-nowrap
        white-space: normal !important
</style>
