<template>
  <BOverlay :show="isFetching">
    <template v-if="data.id">
      <BRow>
        <BCol
            cols="12"
            md="6"
        >
          <FacilityInfoCard
            :item="data"
            @needToUpdate="needToUpdate"
            @updateField="updateField"
            @deleteEntity="deleteEntity"
          />
          <BCard>
            <h4 class="m-0 mb-2">
                Файлы
            </h4>
            <BTabs fill>
                <BTab>
                    <template #title>
                        Объект
                    </template>
                    <FacilityFileUploadCard
                            :items="files"
                            :payload="facilityFilesPagination"
                            :file-types="fileTypes"
                            is-editable
                            @get="getFacilityFiles"
                            @submit="addFiles"
                            @delete="handleDeleteFile"
                    />
                </BTab>
                <BTab>
                    <template #title>
                        Заявки
                    </template>
                    <FacilityFileUploadCard
                            :items="requestFiles"
                            :file-types="fileTypes"
                            :payload="requestFilesPagination"
                            @get="getRequestFiles"
                    />
                </BTab>
            </BTabs>
          </BCard>
        </BCol>
        <BCol
            cols="12"
            md="6"
        >
          <FacilityPropsCard
            :item="data"
            @needToUpdate="needToUpdate"
            @updateField="updateField"
            @updateFields="updateFields"
          />
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="12">
          <FacilityTablesCard
            :key="updated"
            :item="data"
          />
        </BCol>
      </BRow>
    </template>
  </BOverlay>
</template>

<script>
import { ref, toRefs, provide } from '@vue/composition-api';
import {
  BOverlay,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard
} from 'bootstrap-vue';
import { useRouter } from '@core/utils/utils';
import { useStore } from '@/hooks/useStore';
import FacilityInfoCard from '@/components/facilities/FacilityInfoCard.vue';
import { useToast } from '@/hooks/useToast';
import { useI18n } from '@/hooks/useI18n';
import FacilityPropsCard from '@/components/facilities/FacilityPropsCard.vue';
import FacilityTablesCard from '@/components/facilities/FacilityTablesCard.vue';
import {axiosIns} from "@/store/api";

export default {
  components: {
    BOverlay,
    FacilityInfoCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    FacilityPropsCard,
    FacilityTablesCard,
    FacilityFileUploadCard: () => import('@/components/requests/FileUploadCard.vue'),
  },
  props: {
    facilityId: [String, Number],
  },
  setup(props) {
    const { facilityId } = toRefs(props);
    const store = useStore();
    const { successToast, dangerToast } = useToast();
    const { t } = useI18n();
    const { router } = useRouter();
    const updated = ref(null);
    const errors = ref([]);
    const facilityFiles = ref({});
    const facilityFilesPagination = ref({
        page: 1,
        perPage: 5
    });
    const requestFiles = ref({});
    const requestFilesPagination = ref({
        page: 1,
        perPage: 5
    });
    provide('errors', errors);

    const isFetching = ref(false);
    const data = ref({});

      const fileTypes = ref(null);
      const getDictionaries = async () => {
          const result = await store.dispatch('dictionaries/getDictionaries', {
              dictionaries: 'file_types'
          });

          if (result) {
              fileTypes.value = store.state.dictionaries.list.file_types
                  .map((el) => ({text: el.name, value: el.id, disabled: false}));
          }
      };

      getDictionaries();

    const getFacilityFiles = (payload) => {
        store.dispatch('facilities/getFiles', { id: Number(facilityId.value), payload }).then((res) => {
            facilityFiles.value = res.data;
            facilityFilesPagination.value = {
                page: res.data.current_page,
                perPage: res.data.per_page,
                total: res.data.total
            }
        });
    }

    const getRequestFiles = (payload) => {
        store.dispatch('facilities/getRequestFiles', { id: Number(facilityId.value), payload }).then((res) => {
            requestFiles.value = res.data;
            requestFilesPagination.value = {
                page: res.data.current_page,
                perPage: res.data.per_page,
                total: res.data.total
            }
        });
    }

    const getData = async (silent) => {
      if (isFetching.value) return;
      if (!silent) isFetching.value = true;

      await getFacilityFiles(facilityFilesPagination.value);
      await getRequestFiles(requestFilesPagination.value);

      const response = await store.dispatch('facilities/getFacility', facilityId.value);
      isFetching.value = false;
      if (response.result) {
        data.value = response.data;

        store.commit('interactions/setUnreadCount', response.data.unread_interactions);
      }
    };
    getData();

    const needToUpdate = async (silent = true) => {
      await getData(silent);
      updated.value = (new Date()).toISOString();
      if (!silent) {
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
      }
    };
    provide('needToUpdate', needToUpdate);

    const updateField = async ({ field, value, update = false }) => {
      const model = {};
      model[field] = value;
      if (field === 'manager') return;
      const { result } = await store.dispatch('facilities/updateFacility', {
        facilityId: data.value.id,
        model,
      });

      if (result) {
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
        data.value[field] = value;
        if (update) needToUpdate(true);
      } else {
        dangerToast(
          t('errors.something-went-wrong.text'),
          t('errors.message.updateFailedMessage'),
        );
      }
    };

    const updateFields = async ({ model, silent = false, update = true }) => {
      const { result } = await store.dispatch('facilities/updateFacility', {
        facilityId: data.value.id,
        model,
      });

      if (result) {
        if (!silent) {
          successToast(
            t('settings.messageUpdated.title'),
            t('settings.messageUpdated.text'),
          );
        }
        if (update) needToUpdate(true);
      } else {
        dangerToast(
          t('errors.something-went-wrong.text'),
          t('errors.message.updateFailedMessage'),
        );
      }
    };

      const addFiles = async (filesArray) => {
        const formData = new FormData();

        filesArray.forEach((file) => {
            formData.append('files[]', file);
        });

        await axiosIns.post('tools/upload-files', formData)
            .then((res) => {
                if (res.status === 422) {
                    dangerToast(t('filesUpload.uploadSuccessTitle'), res.data.message ?? t("filesUpload.uploadErrorText"))
                } else {
                    const { files } = res.data;

                    store.dispatch('facilities/addFiles', { id: Number(facilityId.value), files: files.map((file) => file.id) }).then(() => {
                        successToast(t('filesUpload.uploadSuccessTitle'), t('filesUpload.uploadSuccessText'));
                    }).then(() => {
                        store.dispatch('facilities/getFiles', { id: Number(facilityId.value), payload: facilityFilesPagination.value }).then((res) => {
                            facilityFiles.value = res.data;
                            facilityFilesPagination.value = {
                                page: res.data.current_page,
                                perPage: res.data.per_page,
                                total: res.data.total
                            }
                        });
                    }).catch(() => {
                        dangerToast(t('file_upload.message.error'));
                    });
                }
            })
            .catch(() => {
                dangerToast(t('file_upload.message.error'));
            });
      }

      const handleDeleteFile = (fileId) => {
          if (fileId) {
              store.dispatch('facilities/deleteFile', { facilityId: Number(facilityId.value), fileId, payload: facilityFilesPagination.value }).then((res) => {
                  if (!res.data.data.length && res.data.current_page > 1) {
                      store.dispatch('facilities/getFiles', { id: Number(facilityId.value), payload: {...facilityFilesPagination.value, page: facilityFilesPagination.value - 1} }).then((res) => {
                          facilityFiles.value = res.data;
                          facilityFilesPagination.value = {
                              page: res.data.current_page,
                              perPage: res.data.per_page,
                              total: res.data.total
                          }
                      });
                  } else {
                      facilityFiles.value = res.data;
                      facilityFilesPagination.value = {
                          page: res.data.current_page,
                          perPage: res.data.per_page,
                          total: res.data.total
                      }
                  }
                  successToast(t('filesUpload.fileSuccessDeleteTitle'), t('filesUpload.fileSuccessDeleteText'))
              })
          }
      }

    const deleteEntity = async () => {
      const { result } = await store.dispatch('facilities/deleteFacility', {
        facilityId: facilityId.value,
      });
      if (result) {
        router.replace('/facilities');
      }
    };

    return {
      data,
      isFetching,
      getFacilityFiles,
      getRequestFiles,
      needToUpdate,
      updateField,
      deleteEntity,
      updateFields,
      addFiles,
      handleDeleteFile,
      updated,
      files: facilityFiles,
      facilityFilesPagination,
      requestFiles,
      requestFilesPagination,
        fileTypes,
    };
  },
};
</script>
